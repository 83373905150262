import { Injectable } from "@angular/core";

export class ConfiguracionConstante {
	//public API_ENDPOINT = "http://127.0.0.1:3000/api/";
	//public API_ENDPOINT = "http://localhost:3000/api/";
	//public API_ENDPOINT = "http://srv-hdel:3001/api/";
	public API_ENDPOINT = "https://migestionsk.sukarne.com/Api/api/"
	public API_ENDPOINT_CI = "https://migestionsk.sukarne.com:8081/"
	//public API_ENDPOINT_CI = "https://srv-migestionsk:8081/"
}

export class LoginConstante {
    public URLLogin = "login";
}

export class ModuloConstante {
	public URLModulos = "modulo/";
	public URLModulosFormulario = "modulos/formularios";
	public URLModulosFormularioActualizar = "modulos/${id}/formularios";
}

export class GrupoConstante {
	public URLGrupos = "grupo/";
	public URLGruposGuardar = "grupo/GuardarGrupo";
	public URLObtenerPuestosGrupo = "grupo/obtenerPuestosGrupos";
	public URLObtenerAllPuestos = "grupo/obtenerPuestos";
	public URLObtenerCreatePuestoRol = "grupo/createPuestoRol";
	public URLObtenerDeletePuestoRol = "grupo/deletePuestoRol";
	public URLObtenerReporteColaboradores = "grupo/obtenerReporteColaboradores";
	//public URLGruposFormularioCrear = "grupo/formularios/";
	//public URLGruposFormularioActualizar = "grupo/formularios";
}

export class FormularioConstante {
	public URLFormularios = "formulario/";
	public URLModulosSinSeleccionar = "modulos/getunselect";
	public URLMenuSubmenuPorGrupo = "grupo/formularios/?GrupoID=";
	public URLMenuSubmenuPermisosPorPosicionGrupo = "grupo/formularios/ObtenerPorPosicionGrupo";
	public URLMenuSubmenuPermisosPorGrupo = "grupo/formulariosPorGrupo?GrupoID=";
}

export class UsuarioConstante {
	public URLUsuarios = "usuario/";
	public URLObtenerUsuariosID = "usuario?opcion=obtenerPorUsuarioID&IdEmpleado=";
	public URLObtenerUsuarioPorNumeroEmpleado = "usuario?opcion=obtenerUsuarioPorNumeroEmpleado&NumeroEmpleado=";
	public URLObtenerGrupoEmpleado = "usuario?opcion=obtenerGrupoEmpleado&NumeroEmpleado=";
	public URLObtenerNumeroEmpleado = "usuario?opcion=obtenerPorNumeroEmpleado&NumeroEmpleado=";
	public URLObtenerEmpleadoPorPosicionID = "usuario?opcion=obtenerEmpleadoPorPosicionID&PosicionID=";
	public URLObtenerColaboradoresACargo = "usuario/obtenerColaboradoresACargo?NumeroEmpleado=";
	public URLObtenerUsuariosPorDireccionID = "usuario/obtenerUsuariosPorDireccionID?DireccionID=";
	public URLObtenerNoJefe = "usuario/obtenerNoJefe?NumeroEmpleado=";

	public URLActualizarPassword = "usuario/actualizarpassword/";
	public URLResetearPassword = "usuario/resetearpassword/";
	public URLUsuariosActivos = "usuario/?Activo=";

	public URLUActualizaUsuarios = "usuario";
	public URLObtenerRelacionCentralizador = 'usuario/centralizador/obtenerRelacion?centralizadorPosicionID=';
	public URLObtenerColaboradoresSinCentralizador = 'usuario/centralizador/obtenerColaboradoresSinRelacion';
	// public URLActualizarGrupoID = "usuario?opcion=actualizarGrupoPermisosUsuario&usuario=";
	public URLAsignarColaboradorAcargo = "usuario/crudColaboradorAsignado";
	public URLObtenerColaboradoresTempACargo = "usuario/obtenerColaboradoresTempACargo?posicion=";
	public URLObtenerColaboradoresTempLibres = "usuario/obtenerColaboradoresTempLibres";
	public URLObtenerJefeTemporal = "usuario/obtenerJefeTemporal?posicion=";
	obtenerJefeTemporal
	public URLconsultarrhpermisos = "usuario/consultarrhpermisos?NumeroEmpleado=";
	public URLguardardato= "usuario/guardardato";
	public URLactualizarPermisoRH= "usuario/actualizarPermisoRH";
	public URLconsultarpermisosdireciones = "usuario/consultarpermisosdireciones?NumeroEmpleado=";
	public URLconsultarpermisosarea = "usuario/consultarpermisosarea?NumeroEmpleado=";
	public URLEliminarRh= "usuario/EliminarRh";
	public URLconsultarCentralizador= "usuario/consultarCentralizador?Centralizador=";
	
}

export class GrupoFormularioConstante {
	public URLGuposFormularioCrear = 'grupoFormulario/crear';
}

export class UsuarioFormularioConstante {
	public URLUsuarioFormularioCrear = 'usuarioFormulario/crear';
}

export class CatalogosContante {
	public URLCatalogoObtenerHerramientas = 'catalogo/Herramientas/ObtenerTodas';
	public URLCatalogoObtenerTiposCambio = 'catalogo/TiposCambio/ObtenerTiposCambio';
	public URLCatalogoObtenerNivelesCambio = 'catalogo/TiposCambio/ObtenerNivelesCambio';
	public URLCatalogoGuardarHerramienta = 'catalogo/Herramientas/GuardarHerramienta?ID='
	public URLCatalogoObtenerHerramientasTodas = 'catalogo/Herramientas/ObtenerAll';
	public URLCatalogoFrecuencias = 'catalogo/Frecuencias/ObtenerTodas';
	public URLCatalogoObtenerFiltradas = 'catalogo/Herramientas/ObtenerTodasFiltrado';
}

export class AutorizacionConstante {
	public URLAutorizacionGuardar = 'autorizaciones/guardar';
	public URLAutorizacionObtenerTodos = 'autorizaciones/obtenerTodos';
	public URLAutorizacionObtenerDetalleFlujo = 'autorizaciones/obtenerDetalleFlujo';


}
export class BitacoraConstante{
	public URLBitacoraObtenerTodos='bitacora/obtenerTodos';
	public URLBitacoraPorDireccion='bitacora/pordireccion';
	public URLBitacoraPorFuncion='bitacora/porfuncion';
	public URLBitacoraPorRangoFecha='bitacora/porrangofecha';
	public URLBitacoraReporteGeneral='bitacora/ReporteGeneralBitacora';

}
export class CatHerramientaConstante{
	public URLCatHerramientaObtenerMatrizCorrelacionPorHerramienta='Autorizacion/CatHerramientas/ObtenerMatrizCorrelacionPorHerramienta';
	public URLCatHerramientaObtenerPuesto2='Autorizacion/CatHerramientas/ObtenerTipoPuesto2';
}

export class TipoCambioConstante {
	public URLTipoCambioGuardar = 'tipoCambio/guardar';
	public URLTipoCambioObtenerTodos = 'tipoCambio/obtenerTodo';
	public URLTipoCambioActualizar = 'tipoCambio/actualizar';
	public URLTipoCambioObtenerUltimaSolicitud = 'tipoCambio/obtenerDiasDesdeUltimoCambio';
}

export class SolicitudCambioConstante {
	public URLSolicitudCambioGuardar = 'solicitudCambio/guardar';
	public URLSolicitudCambioActualizarEstado = 'solicitudCambio/actualizarEstado';
	public URLSolicitudCambioObtenerPorUsuarioID = 'solicitudCambio/obtenerPorUsuarioID';
	public URLSolicitudCambioObtenerPorGrupoAutoriza = 'solicitudCambio/obtenerPorGrupoAutorizaID';
	public URLSolicitudCambioGuardarArchivos = 'solicitudCambio/guardarArchivos';
	public URLSolicitudCambioObtenerPorAdministrador = 'solicitudCambio/obtenerPorAdministrador';
	// public URLSolicitudCambioObtenerPorUsuarioID = 'tipoCambio/actualizar';
}

export class PuestosConstante {
	public URLPuestosObtenerEmpresas = 'puestos/obtenerEmpresas';
	public URLPuestosObtenerAreas = 'puestos/obtenerAreas';
	public URLPuestosObtenerAreasRH = 'puestos/obtenerAreasRH';
	public URLPuestosObtenerFunciones  = 'puestos/obtenerFunciones?IdArea=';
	public URLPuestosObtenerPuestos  = 'puestos/obtenerPuestos?IdFuncion=';
	public URLPuestosObtenerPuestosAreas = 'puestos/obtenerPuestosArea';
	public URLPuestosObtenerEmpleados = 'puestos/obtenerEmpleados?IdPuesto=';
	public URLPuestosObtenerAllFunciones = 'puestos/obtenerAllFunciones';
	public URLPuestosObtenerAllPuestos = 'puestos/obtenerAllPuestos';
	public URLPuestosObtenerUnidades = 'puestos/obtenerUnidades';
	//public URLPuestosGuardar = 'puestos/guardar';
	public URLObtenerSubAreas = 'puestos/obtenerSubAreas';
	public URLAgregarSubArea = 'puestos/agregarSubArea';
	public URLActualizarSubArea = 'puestos/actualizarSubArea';
	public URLEliminarSubArea = 'puestos/EliminarSubArea';
	public URLObtenerTotalDocumentosArea = 'puestos/ObtenerTotalDocumentosArea';
	public URLReasignarDocumentosArea = 'puestos/ReasignarDocumentosArea';
	public URLPuestosObtenerAreasDecisiones = 'puestos/obtenerAreasDecisiones';
	public URLPuestosObtenerAllFuncionesDecisiones = 'puestos/obtenerAllFuncionesDecisiones';
	public URLPuestosObtenerAllPuestosDecisiones = 'puestos/obtenerAllPuestosDecisiones';
	public URLPuestosObtenerAllFuncionesPorAreas = 'puestos/obtenerAllFuncionesPorAreas';
	public URLPuestosObtenerAllPuestosPorFunciones = 'puestos/obtenerAllPuestosPorFunciones';
	public URLPuestosObtenerAllColaboradoresPuestos = 'puestos/obtenerAllColaboradoresPuestos';
	public URLObtenerTodasSubAreas = 'puestos/obtenerTodasSubAreas';
	public URLobtenerPuestosAreaRh = 'puestos/obtenerPuestosAreaRh';
	public URLobtenerDirArea = 'puestos/obtenerDirArea';
	public URLobtenerPuestosAreasDir ='puestos/obtenerPuestosAreasDir';
	public URLobtenerPuestosCK ='puestos/obtenerPuestosCK'
	public URLobtenerAllColaboradoresModCentralizador ='puestos/obtenerAllColaboradoresModCentralizador'
	
}

export class RepositorioConstante {
	public URLRepositorioGuardarArchivo = 'repositorio/guardarArchivo?nombre=';
	public URLRepositorioGuardarAsignacion = 'repositorio/guardarAsignarHerrConAplicacion';
	public URLRepositorioGuardar = 'repositorio/guardar';
	public URLRepositorioDoctosEmpleado = 'repositorio/doctosPorEmpleado'
	public URLRepositorioDoctosCambiarEstado = 'repositorio/CambiarEstado'
	public URLRepositorioDoctosCambiarEstadoR = 'repositorio/CambiarEstadoR'
	public URLRepositorioDoctosAsignadosEmpleado = 'repositorio/doctosAsignadosPorEmpleado'
	public URLRepositorioObtenerArchivo = 'archivos?archivopdf='
	public URLRepositorioGenerarPDF = 'repositorio/generarPDF'
	public URLRepositorioObtenerUltimaVersionHerramienta = 'repositorio/obtenerUltimaVesionHerramienta?IdHerramienta='
}

export class EmpleadoConstante {
	public URLEmpleadoConsultaDoctos = 'empleados/obtenerFiltrado';
	public URLEmpleadoConsulta = 'empleados/obtenerEmpleadosPorFiltro';
	public URLEmpleadoConsultaUDN = 'empleados/obtenerEmpleadosPorFiltroUDN';
	public URLEmpleadoConsultaPorPosicionID = 'empleados/obtenerEmpleadosPorPosicionID'
	public URLEmpleadoConsultaPorNumEmpleado = 'empleados/obtenerEmpleadosPorNumEmpleado'
	public URLEmpleados = 'empleados/obtenerEmpleados'
	public URLDatosRH = 'empleados/obtenerDatosRH'
	public URLEmpleadosAll = 'empleados/obtenerEmpleadosAll'
	public URLEmpleadosCentralizadores = 'empleados/obtenerEmpleadosCentralizadores'
	public URLEmpleadoConsultaPorNombre = 'empleados/obtenerEmpleadosPorNombre';
	public URLobtenerEmpleadosNumero = 'empleados/obtenerEmpleadosNumero'
	public URLEmpleadoConsultaVacante = 'empleados/obtenerEmpleadosPorFiltro_';
	public URLobtenerDataOrdoUsuario = 'empleados/obtenerDataOrdoUsuario';
	public URLEmpleadoConsultaPorPosicionIDActivo = 'empleados/buscarEmpleadoPosicionActivo';
	public URLObtenerColaboradoresActivos='empleados/ObtenerColaboradoresActivos';
}

export class LAConstante {
	public URLLAGuardarSecciones = 'la/guardarSeccion';
	public URLLAConsultarSecciones = 'la/consultarSeccion'
	public URLLAConsultarSeccionesGenerales = 'la/consultarSeccionesGenerales'
	public URLLAGuardarListaActividades = 'la/guardarListaActividades'
	public URLLAConsultarActividades = 'la/obtenerListaActividades'
	public URLLAConsultarCapturaActividades = 'la/obtenerCapturaActividades'
	public URLLAGuardarCapturaActividades = 'la/guardarCapturaActividades'
	public URLLACerrarSemana = 'la/cerrarSemana'
	public URLLAObtenerDiasInasistencia = 'DiasInasistencia/ObtenerTodasDiasInasistencia'
	public URLLAGuardarDiasInasistencia = 'DiasInasistencia/GuardarDiasInasistencia'
	public URLLAObtenerSemanasSeleccionables = 'la/obtenerSemanasSeleccionables'
	public URLLAObtenerActividadesFijas = 'la/obtenerActividadesFijas'
	public URLDiasInasistenciaRemover = 'DiasInasistencia/removerMotivoInasistencia'
	public URLGuardaActividadAutomatico = 'la/guardaActividadAutomatica';
	public URLGuardaActividadGestionEquipoAutomatico = 'la/guardaActividadGestionEquipoAutomatica';
	public URLObtenerPermisoLA = 'la/verificaPermisoLA'
	public URLLAGuardarAvanceActividades = 'la/guardarAvanceActividades'
	public URLLAEditarActividad = 'la/editarActividad'
	public URLLAEliminarActividad = 'la/eliminarActividad'
	public URLLAGeneraPDF = 'la/GeneraPdfLa'
	public URLLAenviarNotificacion = 'la/EnviarNotificacionSolicitudLA'
	public URLLAenviarNotificacionRespuesta = 'la/EnviarNotificacionRespuestaLA'
	public URLDesactivarJuntasDeCarnet = 'la/DesactivarJuntasDeCarnet'
	public URLReactivarColaboradoresRDC = 'la/ReactivarColaboradoresRDC'
	public URLdesactivarSeccionRDC = 'la/desactivarSeccionRDC'
	public URLLAGeneraPdfLaReP = 'la/GeneraPdfLaReP'
	public URLReporteGeneralLA = 'la/ReporteGeneralLA'
	public URLReporteGeneraPorDireccion_LA = 'la/ReporteGeneraPorDireccion_LA'
	public URLReporteDetalladoLA = 'la/ReporteDetalladoLA'
	public URLSemanasDisponiblesReportesLA = 'la/SemanasDisponiblesReportesLA'
	public URLActualizaContadorDescargaDeReportes = 'la/ActualizaContadorDescargaDeReportes'
	public URLSemanasSeleccionablesReporteLA_SLA = 'la/SemanasSeleccionablesReporteLA_SLA'
	public URLobtenerReporteLA_SLA = 'la/obtenerReporteLA_SLA'
	public URLactualizaCapturaActividades = 'la/actualizaCapturaActividades'
	public URLguardaComentariosLA = 'la/guardaComentariosLA'
	public URLguardaActividadesNoRecurrentes = 'la/guardaActividadesNoRecurrentes'
	public URLDesactivarActividadesRDC_LA = 'la/DesactivarActividadesRDC_LA'
	public URLLAguardarActividadesDisenoArquitectura = 'la/guardarActividadesDisenoArquitectura'
	public URLLAReporteUsoVistaPorDireccion='la/obtenerReporteUsoPorDireccionC'

}

export class MotivosInasistenciaConstante {
	public URLMotivosInasistenciaObtenerTodosActivos = 'MotivosInasistencia/obtenerMotivosInasistencia';

}

export class RelacionCentralizadorColaboradorConstante {
	public URLRelacionCentralizadorColaboradoGuardar = 'centralizador/guardar';
	public URLRelacionCentralizadorColaboradoObtener = 'centralizador/obtenerRelacionColaboradores?centralizador=';
	public URLRelacionCentralizadorColaboradoRemover = 'centralizador/remover'
	public URLRelacionCentralizadorColaboradoRemoverCentralizador = 'centralizador/removerCentralizador'
	public URLObtenerCentralizadorColaborador		 = 'centralizador/obtenerCentralizadorColaborador'
	public URLObtenerSinCentralizadorColaborador	 = 'centralizador/obtenerSinCentralizadorColaborador'
}

export class JuntasConstante {
	public URLJuntasObtenerJuntasPorPosicionID = 'juntas/obtenerJuntasPorPosicionID';
	public URLJuntasObtenerJuntasNoRecurrentes = 'juntasNoRecurrentes/obtenerTodas';
	public URLJuntasObtenerFechaInicioSemana = 'juntasNoRecurrentes/fechaInicioSemana';
	public URLJuntasObtenerJuntasCorreo = 'juntas/ObtenerJuntasCorreo';
}

export class ParametrosConstante {
	public URLParametros = 'parametros/ObtenerTodos';
	public URLParametrosModificar = 'parametros/Modificar';
}

export class ReportesConstante {
	public URLReporteColaboradoresSinUsarLA = 'reportes/consultarColaboradoresSinUsarLA';
	public URLReporteListaActividadesSinCambios = 'reportes/consultarLASinModificaciones';

	public URLReporteDocumentos1anio = 'reportes/consultarDocumentos1anio';
	public URLReporteDocumentosColaborador = 'reportes/consultarDocumentosColaborador';

	public URLconsultarNumeroSolicitudesActivas = 'reportes/consultarNumeroSolicitudesActivas';
	public URLconsultarGraficoDocumentosSLA = 'reportes/consultarGraficoDocumentosSLA';
	public URLconsultarGraficoPendientesAdministrador = 'reportes/consultarGraficoPendientesAdministrador';
	public URLconsultarGraficoSolicitudesUDN = 'reportes/consultarGraficoSolicitudesUDN';
	public URLobtenerEstatusFoliosSGC = 'reportes/obtenerEstatusFoliosSGC';

}

export class SesionesConstante {
	public URLObtenerSesionesCompromisos            	= 'compromisos/obtenerSesionesCompromisos';
	public URLObtenerSesionesCompromisosSemanasAnteriores = 'compromisos/obtenerSesionesCompromisosSemanasAnteriores';
	public URLObtenerSesionesCompromisosJefe = 'compromisos/obtenerSesionesCompromisosJefes';
	public URLObtenerSesionesAllCompromisosJefe = 'compromisos/obtenerSesionesAllCompromisosJefes';
	public URLObtenerSesionesCompromisosSemanasAnterioresJefes = 'compromisos/obtenerSesionesCompromisosSemanasAnterioresJefes';
	public URLObtenerSemanasSeleccionables          	= 'compromisos/obtenerSemanasSeleccionables';
	public URLObtenerSemanasSeleccionables52          	= 'compromisos/obtenerSemanasSeleccionables52';
	public URLObtenerSesionesDecisionesTomadas      	= 'compromisos/obtenerSesionesDecisionesTomadas';
	public URLObtenerSesionesCompromisosColaborador 	= 'compromisos/obtenerSesionesCompromisosColaborador';
	public URLObtenerSesionesRiesgos                	= 'compromisos/obtenerSesionesRiesgos';
	public URLObtenerSesionesTemaGeneral            	= 'compromisos/obtenerSesionesTemasGenerales';
	public URLObtenerSesionesRelevante              	= 'compromisos/obtenerSesionesRelevante';
	public URLGuardarNuevoCompromiso            	 	= 'compromisos/guardarNuevoCompromiso';
	public URLGuardarNuevoCompromisoJefe        	 	= 'compromisos/guardarNuevoCompromisoJefe';
	public URLActualizarCompromisoEstatus       	 	= 'compromisos/actualizarCompromisoEstatus';
	public URLActualizarCompromisoJefeEstatus   	 	= 'compromisos/actualizarCompromisoJefeEstatus';
	public URLActualizarDecisionFunciona        	 	= 'compromisos/actualizarDecisionFunciona';
	public URLGuardarNuevaDecision              	 	= 'compromisos/guardarNuevaDecision';
	public URLGuardarNuevoRiesgo                	 	= 'compromisos/guardarNuevoRiesgo';
	public URLGuardarNuevoTemaGeneral           		= 'compromisos/guardarNuevoTemaGeneral';
	public URLGuardarNuevoRelevante             	 	= 'compromisos/guardarNuevoRelevante';
	public URLActualizarCompromiso          	 		= 'compromisos/actualizarCompromiso';
	public URLActualizarCompromisoJefe          	 	= 'compromisos/actualizarCompromisoJefe';
	public URLActualizarRiesgo                  		= 'compromisos/actualizarRiesgo';
	public URLEliminarRiesgo 							= 'compromisos/eliminarRiesgo';
	public URLGuardarArchivo                    	 	= 'compromisos/guardarArchivo';
	public URLObtenerArchivoListado             	 	= 'compromisos/obtenerArchivoListado';
	public URLEliminarArchivo             	 			= 'compromisos/eliminarArchivo';
	public URLActualizarTemaGeneral	             	 	= 'compromisos/actualizarTemaGeneral';
	public URLEliminarTemaGeneral             	 		= 'compromisos/eliminarTemaGeneral';
	public URLActualizarRelevante	             	 	= 'compromisos/actualizarRelevante';
	public URLEliminarRelevante	             	 		= 'compromisos/eliminarRelevante';
	public URLEliminarDecisionFunciona					= 'compromisos/eliminarDecisionFunciona';
	public URLactualizarFocdetalle	             	 	= 'compromisos/actualizarFocdetalle';
	public URLEliminarCompromiso	             	 	= 'compromisos/eliminarCompromiso';

	public URLObtenerCatalogoHerramientas				= 'compromisos/obtenerCatalogoHerramientas';
	public URLObtenerCatalogoHerramientasActivas		= 'compromisos/obtenerCatalogoHerramientasActivas';
	public URLGuardarNuevaHerramienta					= 'compromisos/guardarNuevaHerramienta';
	public URLActualizarCatalogoHerramienta				= 'compromisos/actualizarCatalogoHerramienta';
	public URLActualizarHerramienta				        = 'compromisos/actualizarHerramienta';
	public URLEliminarCatalogoHerramienta				= 'compromisos/eliminarCatalogoHerramienta';

	public URLObtenerFocPorMes							= 'compromisos/obtenerFocPorMes';
	public URLObtenerFocPeriodos						= 'compromisos/obtenerFocPeriodos';

	public URLObtenerListaFocRelevantesPorNumSemana		= 'compromisos/obtenerListaFocRelevantesPorNumSemana';
	public URLGuardarNuevoFocRelevantes					= 'compromisos/guardarNuevoFocRelevante';
	public URLActualizarFocRelevantes					= 'compromisos/actualizarFocRelevante';

	public URLObtenerHK									= 'compromisos/obtenerHK';
	
	public URLObtenerDatos                             = 'compromisos/obtenerDatos';
    public URLActualizarDescripcion                     = 'compromisos/actrDesc';
	public URLverificaPermiso          					= 'compromisos/verificaPermiso';
	public URLobtenerFocvalidacion						= 'compromisos/obtenerFocvalidacion';

	public URLObtenerObtenerSesionesAllCompromisos = 'compromisos/obtenerListaCompromisos_';
	public URLObtenerSemanasSeleccionables_          	= 'compromisos/obtenerSemanasSeleccionables_';


}

export class CulturaConstantes{
	public URLObtenerCulturaTipos            			= 'cultura/obtenerCulturaTipos';
	public URLGuardarNuevoCulturaTipo					= 'cultura/guardarNuevoCulturaTipo';
	public URLActualizarCulturaTipo						= 'cultura/actualizarCulturaTipo';
	public URLEliminarCulturaTipo						= 'cultura/eliminarCulturaTipo';

	public URLObtenerCulturaCategorias            		= 'cultura/obtenerCulturaCategorias';
	public URLObtenerCulturaCategoriasPorTipo			= 'cultura/obtenerCulturaCategoriasPorTipo';
	public URLGuardarNuevoCulturaCategoria				= 'cultura/guardarNuevoCulturaCategoria';
	public URLActualizarCulturaCategoria				= 'cultura/actualizarCulturaCategoria';
	public URLEliminarCulturaCategoria					= 'cultura/eliminarCulturaCategoria';

	public URLObtenerCulturaClasificaciones            	= 'cultura/obtenerCulturaClasificaciones';
	public URLObtenerCulturaClasificacionesPorCategoria = 'cultura/obtenerCulturaClasificacionesPorCategoria';
	public URLGuardarNuevoCulturaClasificacion			= 'cultura/guardarNuevoCulturaClasificacion';
	public URLActualizarCulturaClasificacion			= 'cultura/actualizarCulturaClasificacion';
	public URLEliminarCulturaClasificacion				= 'cultura/eliminarCulturaClasificacion';

	public URLObtenerCulturaDisciplinas            		= 'cultura/obtenerCulturaDisciplinas';	
	public URLActualizarCulturaDisciplina				= 'cultura/actualizarCulturaDisciplina';

	public URLObtenerCulturaConductas           		= 'cultura/obtenerCulturaConductas';
	public URLObtenerCulturaConductasPorClasificacion 	= 'cultura/obtenerCulturaConductasPorClasificacion';
	public URLGuardarNuevoCulturaConducta				= 'cultura/guardarNuevoCulturaConducta';
	public URLActualizarCulturaConducta					= 'cultura/actualizarCulturaConducta';
	public URLEliminarCulturaConducta					= 'cultura/eliminarCulturaConducta';
	
	public URLGuardarNuevoCulturaCumplimiento					= 'cultura/guardarNuevoCulturaCumplimiento';
	public URLObtenerListaStatus								= 'cultura/obtenerListaStatus';
	public URLObtenerListaAreas									= 'cultura/obtenerListaAreas';
	public URLObtenerTotalConductas								= 'cultura/obtenerTotalConductas';
	public URLObtenerListaCulturaCumplimientos					= 'cultura/obtenerListaCulturaCumplimientos';
	public URLObtenerListaCulturaCumplimientosPorId				= 'cultura/obtenerListaCulturaCumplimientosPorID';
	public URLObtenerListaCulturaCumplimientosPorUsuario		= 'cultura/obtenerListaCulturaCumplimientosPorUsuario';
	public URLObtenerListaCulturaCumplimientosDeColaboradores	= 'cultura/obtenerListaCulturaCumplimientosDeColaboradores';
	public URLActualizarCulturaCumplimientoColaboradorCompromiso = 'cultura/actualizarCumplimientoColaboradorCompromiso';

	public URLObtenerListaCumplimientosHistorico				= 'cultura/obtenerListaCumplimientosHistorico';
	public URLObtenerListaCumplimientosHistoricoPorFolio		= 'cultura/obtenerListaCumplimientosHistoricoPorFolio';
	public URLGuardarNuevoCumplimientoHistorico					= 'cultura/guardarNuevoCumplimientoHistorico';

	public URLGuardarArchivoCumplimiento 			= 'cultura/guardarArchivoCumplimiento';
	public URLAbrirArchivoCumplimiento				= 'cultura/abrirArchivoCumplimiento';
	public URLObtenerArchivosListadoCumplimiento 	= 'cultura/obtenerArchivosListadoCumplimiento';

	public URLGuardarAdministradorRH 				= 'cultura/guardarAdministradorRH';
	public URLObtenerAdministradoresRH 				= 'cultura/obtenerAdministradoresRH';
	public URLEliminarAdministradorRH				= 'cultura/eliminarAdministradorRH';

	public URLGuardarAdministrador	 				= 'cultura/guardarAdministrador';
	public URLObtenerAdministradores				= 'cultura/obtenerAdministradores';
	public URLEliminarAdministrador					= 'cultura/eliminarAdministrador';

	public URLGuardarAuditor	 		= 'cultura/guardarAuditor';
	public URLObtenerAuditores			= 'cultura/obtenerAuditores';
	public URLEliminarAuditor			= 'cultura/eliminarAuditor';

	public URLObtenerListaCulturaCumplimientosParaRHPorAdministrador = 'cultura/obtenerListaCulturaCumplimientosParaRHPorAdministrador';
	public URLObtenerListaCulturaCumplimientosParaRHPorUnidad = 'cultura/obtenerListaCulturaCumplimientosParaRHPorUnidad';
	public URLActualizarCulturaCumplimientoEstatusRH = 'cultura/actualizarCulturaCumplimientoEstatusRH';
	public URLActualizarCulturaCumplimientoEstatusRHAceptar = 'cultura/actualizarCulturaCumplimientoEstatusRHAceptar';

	public URLCulturaCumplimientoNotificacion = 'cultura/culturaCumplimientoNotificacion';

	public URLObtenerListaCulturaCumplimientosBuscador = 'cultura/ObtenerListaCulturaCumplimientosBuscador';
	public URLObtenerListaColaboradores = 'cultura/ObtenerListaColaboradores';
	public URLObtenerListaDireccion = 'cultura/ObtenerListaDireccion';
	public URLObtenerListaUNE = 'cultura/ObtenerListaUNE';
	public URLObtenerListaCulturaCumplimientosDeColaboradoresListado	= 'cultura/obtenerListaCulturaCumplimientosDeColaboradoresListado';
	public URLEliminarCumplimientosDetalle = 'cultura/eliminarCumplimientosDetalle ';

	public URLObtenerListaActa = 'cultura/obtenerListaRazonesSociales ';
	public URLEliminarRazon = 'cultura/eliminarRazon ';
	public URLActualizarRazon = 'cultura/actualizarRazon ';
	public URLGuardarNuevaRazon = 'cultura/guardarNuevaRazon ';
	
	public URLObtenerPermisoUsuario = 'cultura/obtenerPermisoUsuario '


	public URLCargaMasivaFolios	= 'cultura/cargaMasivaFolios';
	public URLCargaMasivaConductas	= 'cultura/cargaMasivaConductas';
	public URLCargaMasivaClasificaciones	= 'cultura/cargaMasivaClasificaciones';
	public URLCargaMasivaCategorias	= 'cultura/cargaMasivaCategorias';
	public URLcargaMasivaExcep = 'cultura/cargaMasivaExcep';

	public URLObtenerListaRolesJefe = 'cultura/ObtenerRolesJefes';
	public URLBorrarRolJefe = 'cultura/BorrarRolJefe';
	public URLActualizarRolJefe = 'cultura/ActualizarRolesJefe';
	public URLCreaRolesJefe = 'cultura/CreaRolesJefe';
	public URLObtenerRolJefe = 'cultura/ObtenerRolJefe';
	public URLObtenerRolJefeporCoord = 'cultura/ObtenerRolJefeporCoord';

	public URLobtenerDescripcionCoord = 'cultura/obtenerDescripcionCoord';
	public URLObtenerRolesCoord = 'cultura/ObtenerRolCoordinadorRH';
	public URLObtenerCoordinadoresRH = 'cultura/ObtenerCoordinadoresRH';
	public URLCrearRolCoord = 'cultura/CrearRolCoordinadorRH';
	public URLBorrarRolCoord = 'cultura/BorrarRolCoordinadorRH';
	public URLActualizarRolCoordinadorRH = 'cultura/ActualizarRolCoordinadorRH';

	public URLObtenerCulturaTramos = 'cultura/ObtenerculturaCumplimientoTramos';
	public URLObtenerCoordTramosControls = 'cultura/ObtenerCoordTramosControls';
	public URLObtenerCoordTramo = 'cultura/ObtenerCoordTramo';
	public URLObtenerTramoControl = 'cultura/ObtenerTramoControl';
	public URLObtenerDivisionPersonal = 'cultura/ObtenerDivisionPersonal';
	public URLObtenerUNE = 'cultura/ObtenerUNE';
	public URLObtenerDIRECCION = 'cultura/ObtenerDIRECCION';
	public URLObtenerAREAS = 'cultura/ObtenerAREAS';
	public URLObtenerSubDivisionPersonal = 'cultura/ObtenerSubDivisionPersonal';
	public URLCrearTramoControl = 'cultura/CrearTramoControl';
	public URLBorrarTramosControl = 'cultura/BorrarTramosControl';
	public URLEditTramosControl = 'cultura/EditTramosControl';

	public URLCrearTramoExcepcion = 'cultura/CrearTramoExcepcion'
	public URLObtenerTramosExcepciones = 'cultura/ObtenerTramosExcepciones';
	public URLBorrarTramoExcepcion = 'cultura/BorrarTramoExcepcion';
	public URLActualizarExcepcionActivo = 'cultura/ActualizarExcepcionActivo';
	public URLActualizarExcepcion = 'cultura/ActualizarExcepcion';
}

export class CatalogoDecisionesConstante{
	public URLCatalogoDecisioneGuardarDetalle 		= 'catalogoDecisiones/guardarDetalle';
	public URLCatalogoDecisionesTodos 				= 'catalogoDecisiones/obtenerTodo';
	public URLCatalogoDecisionesDetalleCatalogo     = 'catalogoDecisiones/obtenerDetalleCatalogo?IdCatalogo=';
}

export class DecisionesConstante{
	public URLSubirArchivoDecision			= 'decisiones/subirArchivo';
	public URLGuardarDecision				= 'decisiones/guardar';
	public URLObtenerDecision 				= 'decisiones/obtenerDecisionFolio?FolioSolitud=';
	public URLObtenerAllDecisiones 			= 'decisiones/obtenerAllDecisiones';
	public URLConsultarRutaDecisiones 			= 'decisiones/consultarRuta';
	public URLObtenerDocumentoBase64Decisiones 	= 'decisiones/obtenerDocumentoBase64';
	public URLConsultarCarpetasDecisiones 		= 'decisiones/consultarCarpetas';
	public URLObtenerDoctosCarpetaDecisiones 	= 'decisiones/obtenerDoctosCarpeta';
	public URLGuardarMultipleDecision 			= 'decisiones/guardarMultiple';
	public URLActualizarDecisiones 		        = 'decisiones/actualizarDecisiones';
	public URLEliminarDocumentoDecisiones 		= 'decisiones/eliminarDocumentoDecisiones';
	public URLMoverDocumentosDecisiones			= 'decisiones/moverDocumentosDecisiones';
	public URLObtenerAsignacionDecisiones       = 'decisiones/obtenerAsignacionDecision';
	public URLSubirEvidenciaDecision 			= 'decisiones/subirEvidencia';
	public URLMarcarLeidosDecisiones			= 'decisiones/marcarLeido';
	public URLCargaAsignacionColaboradoresDecisiones			= 'decisiones/cargaAsignacionColaboradores';
	public URLAsignarUsuarioDecisiones 							= 'decisiones/asignarUsuario';
	public URLObtenerReporteDecisiones 							= 'decisiones/reporteDecisiones';
	public URLObtenerReporteDecisionesLectura 					= 'decisiones/obtenerReporteLectura';
	public URLObtenerCodigoDecisiones 							= 'decisiones/obtenerCodigoDecisiones';
	public URLObtenerDetalleLecturaDecisiones 					= 'decisiones/detalleLecturaDecisiones';
	public URLObtenerDocumentosDecisiones						= 'decisiones/obtenerDocumentosDecisiones';
	public URLObtenerPermisoDecisiones							= 'decisiones/verificaPermisoDecisiones';
	public URLAsignacionAutomaticoDecisiones       				= 'decisiones/AsignacionAutomaticoDecision';
	public URLObtenerDecisionesNumEmpleado      				= 'decisiones/obtenerDecisionesNumEmpleado';
	public URLDesasignarDecision	    						= 'decisiones/desasginarDecision';

	public URLObtenerListadoDirecciones	    					= 'decisiones/obtenerListadoDirecciones';
	public URLObtenerListadoAreas    							= 'decisiones/obtenerListadoAreas';
	public URLObtenerListadoFunciones	    					= 'decisiones/obtenerListadoFunciones';
	public URLObtenerListadoPuestos	    						= 'decisiones/obtenerListadoPuestos';

	public URLObtenerReporteResumen	    						= 'decisiones/obtenerReporteResumen';
	public URLObtenerFechasFolio	    						= 'decisiones/ObtenerFechasFolio';
	public URLActualizarVigenciaDecision	    				= 'decisiones/ActualizarVigenciaDecision';
	public URLObtenerTestCorreos	    						= 'decisiones/envioCorreos';
	public URLObtenerTestCorreos2	    						= 'decisiones/testCorreos';
	public URLObtenerDecisionesBase	    						= 'decisiones/obtenerDecisionesBase';
	public URLGuardarDecisionesBase	    						= 'decisiones/gestionDecisionesBase';
	public URLEnviarCorreosDecisionAsignacion	    			= 'decisiones/enviarCorreoDecisionesMasivo';
}


/////////////////////////////////MATRIZ COMUNICACION///////////////////////////////////////////////////////////
export class RelacionJefeEmpleadoConstante{
	public URLRelacionJefeEmpleadoALL = 'realcionJefeEmpleado/all'
	public URLRelacionJefeEmpleado = 'realcionJefeEmpleado'
	public URLRelacionJefeEmpleadoEsJefe = 'realcionJefeEmpleado/esJefe'
}

export class NotificacionesConstante {
	public URLNotificaciones = 'notificaciones'
	public URLNotificacionesALL = 'notificaciones/all'
	public URLNotificacionesAllFiltrado = 'notificaciones/GetAllNotificacionesFiltradas'
}

export class MatrizDeComunicacionConstante{
	
public URLObtenerPuestos = 'matrizComunicacion/Puestos';
public URLObtenerTemporales = 'matrizComunicacion/temporales';
public URLObtenerTemporaleValidado = 'matrizComunicacion/temporalesValidado';
public URLObtenerTemporalePorPuesto = 'matrizComunicacion/temporalesPuesto';

public URLObtenerTemporalesPorTipo = 'matrizComunicacion/temporalesPorTipo';
public URLObtenerTemporalesPorId = 'matrizComunicacion/temporalesPorId';
public URLAgregarTemporales = 'matrizComunicacion/agregarTemporales';
public URLEliminarTemporales = 'matrizComunicacion/elminiarTemporales';
public URLActualizarTemporales = 'matrizComunicacion/actualizarTemporales';

public URLObtenerCatalogo = 'matrizComunicacion/catalogo';
public URLAgregarCatalogo = 'matrizComunicacion/agregarCatalogo';
public URLBorrarDelCatalogo = 'matrizComunicacion/borrarDelCatalogo';
public URLObtenerCatalogoPorPuesto = 'matrizComunicacion/catalogoPorPuestos';
public URLObtenerCatalogoPorEnlace = 'matrizComunicacion/catalogoPorPuestoEnlace';
public URLActualizarCatalogo = 'matrizComunicacion/actualizarCatalogo';
public URLObtenerCaatalogoFiltrado = 'matrizComunicacion/catalogoFiltrado';
public URLObtenerCatalogoPorPuestoFiltrado = 'matrizComunicacion/catalogoPorPuestosFiltrado';


public URLBorrarMC = 'matrizComunicacion/borrarMC';

public URLAgregarJuntasTemporales = 'matrizComunicacion/agregarJuntasTemporales';
public URLObtenerJuntasLA = 'matrizComunicacion/juntasTemporalesLA';
public URLActualizarJuntasTemporales = 'matrizComunicacion/actualizarJuntasTemporales';
public URLEliminarJuntasTEmporales = 'matrizComunicacion/borrarJuntasTemporales';

public URLEmpleados = 'matrizComunicacion/ObtenerEmpleados';
public URLObtenerPorNumero =  'matrizComunicacion/ObtenerPorNumero';

public ObtenerMCIndividuales = 'matrizComunicacion/ObtenerMCIndividuales';
public ObtenerMatrizIndividualPorPuesto = 'matrizComunicacion/obtenerMatrizIndividualPorPuesto';
public ObtenerMCPorPuestos = 'matrizComunicacion/ObtenerMCPorPuestos';
public URLGuardarMatrizComunicacion = 'matrizComunicacion/GuardarMC';
public URLObtenerMCPorID = 'matrizComunicacion/ObtenerMC';
public URLBuscarPorEmpleado = 'matrizComunicacion/BuscarPorEmpleado';
public URLBuscarPuestosMC = 'matrizComunicacion/BuscarPuestosenMC';
public URLBuscarPuestosSinEstatus = 'matrizComunicacion/BuscarPuestosSinEstatus';
public URLBuscarMCPorPuestos = 'matrizComunicacion/BuscarMCposPuestos';
public URLBuscarMcPorEmpleado = 'matrizComunicacion/BuscarMatricesDelEmpleado';
public URLActualizarMC = 'matrizComunicacion/ActualizarMC';
public URLBuscarPuestosMCFiltrados = 'matrizComunicacion/BuscarPuestosFiltrados';
public URLBuscarMCPorPuestosFiltrado = 'matrizComunicacion/BuscarMCPorPuestosFiltrados';
public URLBorrarMatriz = 'matrizComunicacion/BorrarMatriz';


public URLNotificacionesPorEmpleado = 'matrizComunicacion/NotificacionesPorPuesto';
public URLNotificacionesAdministrador = 'matrizComunicacion/NotificacionesAdministrador';
public URLActualizarNotificacionesMC = 'matrizComunicacion/ActualizarNotificaciones';
public URLActualizarCentralizador = 'matrizComunicacion/ActualizarCentralizador';
public URLValidarMCPorColaborador = 'matrizComunicacion/ValidarMCPorColaborador'

public URLUsuarioPorPosicion = 'matrizComunicacion/UsuarioPorPuesto';
public URLObtenerPorPares = 'matrizComunicacion/ObtenerJuntasPorPares';
public URLJuntas = 'matrizComunicacion/ObtenerJuntas';

public URLGuardarJuntaMC = 'matrizComunicacion/GuardarJuntaMC';
public URLGuardarJuntasMatrizComunicacion = 'matrizComunicacion/GuardarJuntasMatrizComunicacion'
public URLObtenerJuntasMC = 'matrizComunicacion/ObtenerJuntasMC';
public URLBorrarJuntasMC = 'matrizComunicacion/BorrarJuntasMC';
public URLBorrarJuntasPorPosiciones = 'matrizComunicacion/BorrarJuntasPorPosiciones';

public URLCentralziador = 'matrizComunicacion/BuscarCentralizador';

public URLBuscarJefe = 'matrizComunicacion/BuscarJefe';

public URLObtenerRechazados = 'matrizComunicacion/Rechazados';

}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////ORDOS////////////////////////////////////////////////////////////////
export class RespoInerentesConstante{
	public URLRespoIne = "RespoIne";
	public URLObtener = "respoin/obtener";
	public URLActvas = "respoin/obtenerActivas";
	public URLCargos = "respoin/obtenerCargos";
	public URLActualizar = "respoin/actualizarRespo";
	public URLBorrar = 'respoin/borrar';
	public URLGuardar = 'respoin/guardar';
	public URLActualizar_dragydrop = 'respoin/actualizar_dragydrop';
	public URLGuardarIne = 'respoin/guardarIne';
	public URLRespos = 'respoin/obtenerRespos';
	public URLRespos_Eliminados = 'respoin/obtenerRespos_Eliminados';
	public URLAcRespo = 'respoin/actualizar';
	public URLBoRespo = 'respoin/borrarRes';
	
}


export class ORDOSConstante{
	public URLOrdos = "Ordos";
	public URLObtener ='ordos/obtener';
	public URLObtenerID = 'ordos/obtenerOrdoID';
	public URLGInicial = 'ordos/guardadoinicial';
	public URlPuestos = 'ordos/obtenerPuestos';
	public URlUltimoID = 'ordos/obtenerID';
	public URLActualizar = 'ordos/actualizar';
	public URLActualizarORDO = 'ordos/actualizarORDO';
	public URLACtualizarDirecto = 'ordos/actualizarDirecto';
	public URLObtenerEspeciales = 'ordos/especiales';
	public URLObtenerOrdosEliminados_log = 'ordos/OrdosEliminados_log';
	public URLObtenerOrdosEliminados = 'ordos/OrdosEliminados';
	public URLObtenerCorreoArqOrganizacionales = 'ordos/CorreoArqOrganizacionales';
	


	public URLObtenerReportesORDO = 'ordos/ObtenerReporteOrdo';

	public URLAreas = 'ordos/obtenerAreas';
	public URLIDAreas = 'ordos/obtenerIdAreas';

	public URLGExpe = 'ordos/guardarExpe';
	public URLOExpe = 'ordos/obtenerExpe';
	public URLBExpe = 'ordos/borrarExpe';
	public URLAExpe = 'ordos/actualizarExpe'

	public URLGHer = 'ordos/guardarHer';
	public URLOHer = 'ordos/obtenerHer';
	public URLHerMiPerfil = 'ordos/obtenerHerMiPerfil';
	public URLOHer_Eliminados = 'ordos/obtenerHer_Eliminados';
	public URLBHer = 'ordos/borrarHer';
	public URLAHer = 'ordos/actualizarHer';

	public URLNnull = 'ordos/obtenerOrdoIDNull';
	public URLAsignarNnull = 'ordos/obtenerAsignarPorIDNull';

	public URLOPos = 'ordos/obtenerPos';
	

	public URLOAsig = 'ordos/obtenerAsig';
	public URLGAsig = 'ordos/guardaarAsig';
	public URLAAsig = 'ordos/actualizarAsig';
	public URLOAsigF = 'ordos/obtenerAsigFil';
	public URLAsigID = 'ordos/obtenerAsigID';
	public URLApPos = 'ordos/obtenerAsigPos';
	public URLBAsig = 'ordos/borrarAsignados';

	public URLObtenerEmpleadoPorPuesto = 'ordos/obtenerEmpleadoPorPuesto';
	public URLObtenerCentralizador = 'ordos/obtenerCentralizador';
	public URLObtenerDProcesos = 'ordos/obtenerDProceso';
	public URLObtenerCentralizadorPorID = 'ordos/obtenerCentralizadorPorID';
	public URLObtenerJefe = 'ordos/obtenerJefe';
	public URLObtenerPorPosicion = 'ordos/obtenerPorPosicion';
	public URLObtenerEmpleados = 'ordos/obtenerEmpleados';

	public URLObtenerNotificaciones = 'ordos/obtenerNotificaciones';
	public URLGuardarNotificacion = 'ordos/guardarNotificaciones';
	public URLActualizarNotificacion = 'ordos/actualizarNotificaciones';
	public URLObtenerArqui = 'ordos/obtenerNotifArqui';
	public URLActualizarPorORDO = 'ordos/actualizarNotificacionesPordOrdo';
	public URLObtenerNotificacionPorFlujo = 'ordos/obtenerNoficiacionesFlujo';
	public URLEliminarNotificacion = 'ordos/eliminarNotificacion';
	public URLActualizarTemporales ='ordos/actualizarNofiticacionesTemporales';
	public URLActualizarTempPorORDO ='ordos/actualizarTemporalesporORDO';
	public URLObtenerNotPorORDO = 'ordos/obtenerNotificacionesPorORDO';
	public URLFiltrados = 'ordos/obtenerNotificacionesFiltradas';

	public URLUltimoFolio = 'ordos/obtenerUltimoFolio';
	public URLUltimaNoti = 'ordos/obtenerUltimaNotificacion';

	public URLObtenerRechazados = 'ordos/obtenerRechazados';
	public URLObtenerRechazadosPorId = 'ordos/obtenerRechazadosPorId';
	public URLGuardarRechazados = 'ordos/guardarRechazados';
	public URLActualizarRechazados = 'ordos/actualizarRechazados';

	public URLObtenerAprobados = 'ordos/obtenerAprobados';
	public URLGuardarAprobados = 'ordos/guardarAprobados';
	public URLBorrarAprobados = 'ordos/borrarAprobados';
	public URLObtenerFiltrados = 'ordos/obtenerAprobadosFiltrado';
	public URLActualizarAprobados = 'ordos/actualizarAprobados';

	public URLActualizarEstado = 'ordos/actualizarEstado';

	public URLObtenerTemporal = 'ordos/obtenerOrdoTemporal';
	public URLGuardarTemporal = 'ordos/guardarOrdoTemporal';
	public URLActualizarTemporal = 'ordos/actualizarOrdoTemporal';
	public URLBorrarTemporal = 'ordos/borrarOrdoTemporal';
	public URLObtenerTemporalID = 'ordos/obtenerOrdoTemporalPorId';

	public URLDireccion = 'ordos/obtenerDirecciones';

	public URLBorrador = 'ordos/obtenerBorrador';

	public URLObtenerFormularioID = 'ordos/obtenerFormulario';

	public URLObtenerGrupoFormulario = 'ordos/obtenerGrupoFormulario';

	public URLObtenerUsuarioFormulario  = 'ordos/obtenerUsuarioFormulario';

	public URLGeneraPdfOrdo = 'ordos/GeneraPdfOrdo';

	public URLTotalIndirectosOrgani = 'ordos/obtenerTotalIndirectosOrgani';

	public URLPuestosOrgani = 'ordos/obtenerPuestosOrgani';

	public URLPuestosOrganiEditable = 'ordos/obtenerPuestosOrganiEditable';

	public URLPuestoscheckAll = 'ordos/obtenerPuestoscheckAll';

	public URLActualizaPuestosCheck = 'ordos/actualizaPuestosCheck';

	public URLAgregarFuncionCheck = 'ordos/agregarFuncionCheck';

	public URLEliminarOrdo = 'ordos/EliminarOrdo';
	
	
}



export class UnidadesDeNegocioConstante {
	public URLUnidadesDeNegocio = 'UnidadesDeNegocio/obtenerTodos';
}

export class CatalogoCtrlDocumentalConstante {
	public URLObtenerCatalogos = 'CatalogoCtrlDoc/obtenerCatalogos';
	public URLObtenerDetallesCatalogos = 'CatalogoCtrlDoc/obtenerDetallesCatalogos';
	public URLObtenerDetallePorId = 'CatalogoCtrlDoc/obtenerDetallePorId';
	public URLObtenerNiveles = 'CatalogoCtrlDoc/obtenerNiveles';
	public URLObtenerNivelPorId = 'CatalogoCtrlDoc/obtenerNivelPorId';
	public URLAgregarNivel = 'CatalogoCtrlDoc/agregarNivel';
	public URLAgregarDetalleCatalogo = 'CatalogoCtrlDoc/agregarDetalleCatalogo';
	public URLActualizarDetalleCatalogo = 'CatalogoCtrlDoc/actualizarDetalleCatalogo';
	public URLActualizarNivel = 'CatalogoCtrlDoc/actualizarNivel';
}

export class SolicitudCtrlDocumentalConstante {
	public URLGuardarSolicitud = 'SolicitudCtrlDocumental/guardar';
	public URLConsultarSolicitudes = 'SolicitudCtrlDocumental/consultarSolicitudes';
	public URLCancelarSolicitudes = 'SolicitudCtrlDocumental/cancelarSolicitud';
	public URLConsultarRelacionados = 'SolicitudCtrlDocumental/consultarRelacionados';
	public URLConsultarRelacionadosSolicitud = 'SolicitudCtrlDocumental/consultarRelacionadosSolicitud';
	public URLObtenerSolicitudesAutorizacion = 'SolicitudCtrlDocumental/obtenerSolicitudesAutorizacion';
	public URLObtenerDocumento = 'SolicitudCtrlDocumental/obtenerDocumento';
	public URLObtenerDocumentoCtrlDoc = 'SolicitudCtrlDocumental/obtenerDocumentoCtrlDoc';
	public URLAutorizarSolicitud = 'SolicitudCtrlDocumental/autorizarSolicitud';
	public URLReenviarSolicitud = 'SolicitudCtrlDocumental/reenviarSolicitud';
	public URLEnviarMatrizSeguimiento = 'SolicitudCtrlDocumental/enviarMatrizSeguimiento';
	public URLReenviarMatrizSeguimiento = 'SolicitudCtrlDocumental/reenviarMatrizSeguimiento';
	public URLVinculacionSolRechazadasMS = 'SolicitudCtrlDocumental/VinculacionSolRechazadasMS';
	public URLComentariosSeguimiento = 'SolicitudCtrlDocumental/consultarComentarios';
	public URLComentariosSeguimientoPorRol = 'SolicitudCtrlDocumental/consultarComentariosPorRol';
	public URLActualizarGteArquitectura = 'SolicitudCtrlDocumental/actualizarGteArquitectura';
	public URLAutorizarMatrizSeguimiento = 'SolicitudCtrlDocumental/autorizarMatrizSeguimiento';
	public URLAltaDocumentoSolicitud = 'SolicitudCtrlDocumental/altaDocumentoSolicitud';
	public URLActualizarDocumentoSolicitud = 'SolicitudCtrlDocumental/actualizarDocumentoSolicitud';
	public URLBajaDocumentoSolicitud = 'SolicitudCtrlDocumental/bajaDocumentoSolicitud';
	public URLGuardarDocumentoAdjunto = 'SolicitudCtrlDocumental/guardarDocumentoAdjunto';
	public URLConsultarAdjuntosSolicitud = 'SolicitudCtrlDocumental/consultarAdjuntosSolicitud';
	public URLEliminarDocumentosMatrizSeguimiento = 'SolicitudCtrlDocumental/eliminarDocumentosMatrizSeguimiento';
	public URLActualizarAsignacionSolicitud = 'SolicitudCtrlDocumental/actualizarAsignacionSolicitud';
	public URLObtenerMotivosRechazo = 'SolicitudCtrlDocumental/obtenerMotivosRechazo';
	public URLObtenerSolicitudesDeMatriz = 'SolicitudCtrlDocumental/obtenerSolicitudesDeMatriz';
	public URLActualizarDocumentoMatrizReenvioSolicitud = 'SolicitudCtrlDocumental/actualizarDocumentoMatrizReenvioSolicitud';
	public URLDesligarSolicitudMatriz = 'SolicitudCtrlDocumental/desligarSolicitudMatriz';
	public URLActualizarSolicitudAplicaMatriz = 'SolicitudCtrlDocumental/actualizarSolicitudAplicaMatriz';
	public URLConsultarRechazosDeSolicitud = 'SolicitudCtrlDocumental/consultarRechazosDeSolicitud';
	public URLLiberarSolicitudParaRSGC = 'SolicitudCtrlDocumental/liberarSolicitudParaRSGC';
	public URLActualizarDatosSolicitudMatriz = 'SolicitudCtrlDocumental/actualizarDatosSolicitudMatriz';
	public URLEliminarDocumentoTemporal = 'SolicitudCtrlDocumental/eliminarDocumentoTemporal';
	public URLGuardarDocumentoMatrizSeguimiento = 'SolicitudCtrlDocumental/guardarDocumentoMatrizSeguimiento';
	public URLVerificarAutorizador = 'SolicitudCtrlDocumental/VerificarAutorizador';
	public URLGuardarHistorialCargaDeDocumento = 'SolicitudCtrlDocumental/guardarHistorialCargaDeDocumento';
	public URLObtenerUdnSolicitud = 'SolicitudCtrlDocumental/obtenerUdnSolicitud';
	public URLEnviarCorreoMatrizSeguimiento = 'SolicitudCtrlDocumental/enviarCorreoMatrizSeguimiento';
	public URLRevertirEstatusSolicitud = 'SolicitudCtrlDocumental/revertirEstatusSolicitud';	
	public URLNotificarUsuario = 'SolicitudCtrlDocumental/notificarUsuario'; 	 
	public URLQuitarComentario = 'SolicitudCtrlDocumental/quitarComentario'; 	
	public URLQuitarHistorial= 'SolicitudCtrlDocumental/quitarHistorial'; 	
	public URLGuardarDocumentoSolicitud= 'SolicitudCtrlDocumental/guardarDocumentoSolicitud';
	public URLVerificarCodigoSolicitud = 'SolicitudCtrlDocumental/VerificarCodigo'; 
	public URLObtenerIdRolAutorizadorRechazado = 'SolicitudCtrlDocumental/ObtenerIdRolAutorizadorRechazado'; 
	public URLGuardarAdjuntoTempoRSG = 'SolicitudCtrlDocumental/GuardarAdjuntoTempoRSG';
}

export class ControlDocumentalConstante {
	public URLConsultarCarpetas = 'controlDocumental/consultarCarpetas';
	public URLConsultarSubCarpetas = 'controlDocumental/consultarSubCarpetas';
	public URLConsultarRolCD = 'controlDocumental/consultarRolCD';
	public URLConsultarRolesCD = 'controlDocumental/consultarRolesCD';
	public URLCrearCarpeta = 'controlDocumental/crearCarpeta';
	public URLCambiarNombreCarpeta = 'controlDocumental/cambiarNombreCarpeta';
	public URLEliminarNombreCarpeta = 'controlDocumental/eliminarNombreCarpeta';
	public URLCambiarEstatusObsoleto = 'controlDocumental/cambiarEstatusObsoleto';
	public URLObtenerDoctosCarpeta = 'controlDocumental/obtenerDoctosCarpeta';
	public URLObtenerDoctosEliminados = 'controlDocumental/obtenerDocumentosEliminados';
	public URLSubirDocumento = 'controlDocumental/subirDocumento';
	public URLEliminarDocumento = 'controlDocumental/eliminarDocumento';
	public URLEliminarDocumentoAdmin = 'controlDocumental/eliminarDocumentoAdmin';
	public URLRestaurarDocumento = 'controlDocumental/restaurarDocumento';
	public URLEliminarCarpeta = 'controlDocumental/eliminarCarpeta';
	public URLAsignarPermisosVisualizacion = 'controlDocumental/asignarPermisosVisualizacion';
	public URLQuitarPermisosVisualizacion = 'controlDocumental/quitarPermisosVisualizacion';
	public URLObtenerPermisosVisualizacion = 'controlDocumental/obtenerPermisosVisualizacion';
	public URLAsignarExcepcion = 'controlDocumental/asignarExcepcion';
	public URLQuitarExcepcion = 'controlDocumental/quitarExcepcion';
	public URLObtenerExcepcion = 'controlDocumental/obtenerExcepcion';
	public URLAsignarResponsablesCarpetas = 'controlDocumental/asignarResponsablesCarpetas';
	public URLQuitarResponsablesCarpetas = 'controlDocumental/quitarResponsablesCarpetas';
	public URLObtenerResponsablesCarpetas = 'controlDocumental/obtenerResponsablesCarpetas';
	public URLConsultarRuta = 'controlDocumental/consultarRuta';
	public URLObtenerDocumentoBase64 = 'controlDocumental/obtenerDocumentoBase64';
	public URLObtenerDocumentoBase64GD = 'controlDocumental/obtenerDocumentoBase64GD';
	public URLObtenerDocumentosPorUsuario = 'controlDocumental/obtenerDocumentosPorUsuarioId';
	public URLObtenerDocumentos = 'controlDocumental/obtenerDocumentos';
	public URLobtenerDocumentoPorId = 'controlDocumental/obtenerDocumentoPorId';
	public URLObtenerListaMaestra = 'controlDocumental/obtenerListaMaestra';
	public URLObtenerDocumentosPorUnidadNegocio = 'controlDocumental/obtenerDocumentosPorUnidadNegocio';
	public URLObtenerDocumentosMatrizPorUnidadNegocio = 'controlDocumental/obtenerDocumentosMatrizPorUnidadNegocio';
	public URLVerificarRuta = 'controlDocumental/verificarRuta';
	public URLCargarDocumento = 'controlDocumental/cargarDocumento';
	public URLActualizarRegistro = 'controlDocumental/actualizarDocumento';
	public URLAsignarRol = 'controlDocumental/asignarRol';
	public URLEliminarRol = 'controlDocumental/eliminarRol';
	public URLAgregarAreasXUDN = 'controlDocumental/agregarAreasXUDN';
	public URLObtenerUDNDocumento = 'controlDocumental/obtenerUDNDocumento';
	public URLActualizarRutaDocumento = 'controlDocumental/actualizarRutaDocumento';
	public URLObtenerDataDocumento = 'controlDocumental/ObtenerDataDocumento';
	public URLActualizarInfoDocumento = 'controlDocumental/ActualizarInfoDocumento';

	//REPORTES DE PERMISOS PARA LOS COLABORADORES
	public URLObtenerReporteLSTipoPermiso = 'controlDocumental/ObtenerReporteLSTipoPermiso';
	public URLObtenerReporteLSCarpetas = 'controlDocumental/ObtenerReporteLSCarpetas';
	public URLObtenerReportePermisosActivos = 'controlDocumental/ObtenerReportePermisosActivos';

	//DESCARGA COMPLETA DE UNA CARPETA
	public URLDescargarCarpetaCompleta = 'controlDocumental/DescargarCarpetaCompleta';
	public URLMoverCarpeta = 'controlDocumental/MoverCarpeta';
	
	//PARA LISTAS DE VERIFICACION
	public URLObtenerListadoPeriodos = 'controlDocumental/ObtenerListadoPeriodos';
	public URLObtenerListadoEncargados = 'controlDocumental/ObtenerListadoEncargados';
	public URLObtenerListadoEncargadosALL = 'controlDocumental/ObtenerListadoEncargadosALL';
	public URLObtenerListadoPeriodosEncargadoArea = 'controlDocumental/ObtenerListadoPeriodosEncargadoArea';
	public URLObtenerListadoUNEDisponiblePorPeriodo = 'controlDocumental/ObtenerListadoUNEDisponiblePorPeriodo';
	public URLCrearPeriodoListaVerificacion = 'controlDocumental/CrearPeriodoListaVerificacion';
	public URLActualizarFechaPeriodoListaVerificacion = 'controlDocumental/ActualizarFechaPeriodoListaVerificacion';
	public URLLiberarPeriodoAreaListaVerificacionAll = 'controlDocumental/LiberarPeriodoAreaListaVerificacionAll';
	public URLLiberarPeriodoAreaListaVerificacion = 'controlDocumental/LiberarPeriodoAreaListaVerificacion';
	public URLActualizarPeriodoAreaListaVerificacion = 'controlDocumental/ActualizarPeriodoAreaListaVerificacion';
	public URLCumplirPeriodoAreaListaVerificacion = 'controlDocumental/CumplirPeriodoAreaListaVerificacion';
	public URLRevertirPeriodoAreaListaVerificacion = 'controlDocumental/RevertirPeriodoAreaListaVerificacion';
	public URLObtenerAreasPorPeriodoUDN = 'controlDocumental/ObtenerAreasPorPeriodoUDN';
	public URLObtenerDetalleAreasPorPeriodoUDN = 'controlDocumental/ObtenerDetalleAreasPorPeriodoUDN';
	public URLObtenerDetalleAreasPorPeriodoUDNLV = 'controlDocumental/ObtenerDetalleAreasPorPeriodoUDNLV';
	public URLObtenerListaVerificacionAll = 'controlDocumental/ObtenerListaVerificacionAll';
	public URLObtenerListaVerificacionReporte = 'controlDocumental/ObtenerListaVerificacionReporte';
	public URLObtenerListaVerificacionReporteDetalle = 'controlDocumental/ObtenerListaVerificacionReporteDetalle';
	public URLActualizarNombreListaVerificacion = 'controlDocumental/ActualizarNombreListaVerificacion';
	public URLObtenerReporteProductividad = 'controlDocumental/ObtenerReporteProductividad';
	public URLObtenerSemanasReporteProductividadCD = 'controlDocumental/ObtenerSemanasReporteProductividadCD';
	public URLObtenerPermisoEMP = 'controlDocumental/ObtenerPermisoEMP';
	public URLEmpleados_CentralizadorGteArq = 'controlDocumental/ObtenerEmpleados_CentralizadorGteArq'
}

export class PermisosCtrlDocConstante {
	public URLObtenerPermisosCarpeta = 'PermisosCtrlDoc/ObtenerPermisosIDCarpeta';
	public URLAgregarPermisosCarpeta = 'PermisosCtrlDoc/AgregarPermisoCtrlDoc';
	public URLEliminarPermiso = 'PermisosCtrlDoc/EliminarPermisoCtrlDoc';

}

export class RolesCtrlDocConstante {
	public URLObtenerColaboradoresPorRol = 'rolesControlDocumental/obtenerColaboradoresPorRol';
	public URLObtenerColaboradorPorUsuarioId = 'rolesControlDocumental/obtenerColaboradorPorIdUsuario';
	public URLEliminarAsignacion = 'rolesControlDocumental/eliminarAsignacion';
	public URLConsultarAsignacionesColaborador = 'rolesControlDocumental/consultarAsignacionesColaborador';
	public URLObtenerColaboradorPorPosicion = 'rolesControlDocumental/obtenerColaboradorPorPosicion';
}

export class UsuariosSinDominioConstante {
	public URLObtenerEmpleadoSinUser = 'empleadosSin/obtenerEmpleadoSinUser';
	public URLObtenerEmpleadoSinGuardar = 'empleadosSin/guardar';
	public URLObtenerEmpleadoSinActualizaPassword = 'empleadosSin/actualizaPassword';

}	
///////////////////////////////////////////////////////////////////////////////////////
export class GteArquitecturaProcesosConstante{
	public URLObtenerGerentes = 'gteArqui/obtener';
	public URLGuardar = 'gteArqui/guardar';
	public URLObtenerRelacion = 'gteArqui/buscarRelacion';
	public URLBuscarAreas = 'gteArqui/buscarAreas';
	public URLEliminarRelacion = 'gteArqui/eliminarRelacion';
	public URLEliminarPorID = 'gteArqui/eliminarRelacionPorId';
	public URLDireccionPorId = 'gteArqui/DireccionesPorId';
	public URLValidarRelaciion = 'gteArqui/validarRelacion';
}

/////////////////////////// Notificaciones Dashboard //////////////////////////////////
export class DashboardNotificacionesConstante{
	/// Historial
		public URLNotificacionesHistorial = 'dashboard/NotificacionesHistorial'
	/// ORDOS	
		public URLObtenerPorUsuario= 'dashboard/obtenerPorUsuario';
		public URLObtenerPorORDO='dashboard/NotificacionesOrdo';
		public URLObtenerPorORDOArquitecto='dashboard/NotificacionesOrdoArquitecto';
	/// Decisiones
		public URLNotificacionesDecisiones ='dashboard/NotificacionesDecisiones';
		public URLObtenerPorDecisiones ='dashboard/NotificacionesDecisionesAdministrador';
		public URLNotificacionesDecisionesLeer ='dashboard/NotificacionesDecisionesLeer';
		public URLNotificacionesDecisionesTotalLeer ='dashboard/NotificacionesDecisionesTotalLeer';

	/// Sesiones
		public URLObtenerPorSesiones = 'dashboard/NotificacionesSesiones';
	/// LA
		public URLNotificacionesLA = 'dashboard/NotificacionesLA';
		public URLNotificacionesLAAdmin = 'dashboard/NotificacionesLAAdmin';

	// Acceso Temporal
		public URLNotificaiconesAccesoTEMP = 'dashboard/NotificacionesAccesoTEMP';

	// Juntas
		public URLNotificacionesJuntasAdmin = 'dashboard/NotificacionesJuntasAdmin';

	// Juntas compromisos
	public URLNotificacionesJuntasCompromisosAdmin = 'dashboard/NotificacionesJuntasCompromisosAdmin';

	// Total Juntas Compromisos
	public URLObtenerTotalJYC = 'dashboard/ObtenerTotalJYC';

	// Total Juntas Atendidas Compromisos
	public URLObtenerAtendidasJYC = 'dashboard/ObtenerAtendidasJYC';

	// Modo SK
	public URLNotificacionesModoSK = 'dashboard/NotificacionesModoSK';
	public URLNotificacionesModoSK_Solicitante = 'dashboard/NotificacionesModoSK_Solicitante';
	public URLNotificacionesModoSKTotalizados = 'dashboard/NotificacionesModoSKTotalizados';

	// Cultura de cumplimiento
	public URLNotificacionesCC_usuario = 'dashboard/NotificacionesCC_Usuario';
	public URLNotificacionesCC_jefe = 'dashboard/NotificacionesCC_Jefe';
	public URLNotificacionesCC_rh = 'dashboard/NotificacionesCC_RH';

	//Dashboard Propuesta de Mejora
	public URLNotificaciones_PropuestadeMejora = 'dashboard/Notificaciones_PropuestadeMejora';
	}

export class PreguntasFrecuentesConstantes {
  constructor(private baseURL: string = "preguntasFrecuentes/") {}

  public URLObtenerPreguntas: string = this.baseURL + "preguntas";
  public URLGuardarPregunta: string = this.baseURL + "guardarPregunta";
  public URLBorrarPregunta: string = this.baseURL + "borrarPregunta";
  public URLActualizarPregunta: string = this.baseURL + "actualizarPregunta";
  public URLEnviarCorreo: string = this.baseURL + "contacto";
}

export class ChecklistConstante{
	public URLListadoBloques = 'checklist/obtenerlistado';
	public URLCatalogoCh = 'checklist/obtenercatalogoscheck';
	public URLGuardarBloque =  'checklist/guardarbloque';
	public URLactualizarclasificacion = 'checklist/actualizarclasificacion';
	public URLguardarpuntoevaluar=  'checklist/guardarpuntoevaluar';
	public URLpuntoevaluar = 'checklist/puntoevaluar';
	public URLactpuntoevaluar = 'checklist/actualizarpunto';
	public URLobtenerListacatalogo=  'checklist/obtenerListacatalogo';
	public URLcrearcatalogo = 'checklist/crearcatalogo';
	public URLactualizarcatalog = 'checklist/actualizarcatalog';
	public URLobtenerListadescripcion=  'checklist/obtenerListadescripcion';
	public URLcreardescripcion = 'checklist/creardescripcion';
	public URLactualizardescripcion = 'checklist/actualizardescripcion';
	public URLlistapuntonevaluar = 'checklist/listapuntonevaluar';
	public URLlistAllpointevaluate='checklist/puntoevaluar';
	public URLlistColaboradorCheckList='checklist/ObtenerColaboradorSeleccionado';

	public URLeliminarclasificacion = 'checklist/eliminarclasificacion';
	public URLeliminarcatalogo = 'checklist/eliminarcatalogo';
	public URLeliminardescripcion = 'checklist/eliminardescripcion';
	public URLeliminarpuntoevaluar = 'checklist/eliminarpuntoevaluar';

	public URLobtenerListadescripcionfilstro =  'checklist/obtenerListadescripcionfilstro';
	public URLguardarlistado =  'checklist/guardarlistado';
	public URLconsultarchecklist =  'checklist/consultarchecklist';
	public URLObtenerTodoscheckList='checklist/ObtenerTodosCheckList';
	public URLactualizarchecklist =  'checklist/actualizarchecklist';
	public URLeliminarlistado =  'checklist/eliminarchecklist';
	public URLEnviarlistado =  'checklist/marcarEnviada';
	public URLEnviarCorreoResponsable =  'checklist/correoSolicitud';
	public URLRechazarLista =  'checklist/rechazarLista';
	public URLAutorizarLista =  'checklist/autorizarLista';
	public URLEnviarCorreoRechazar =  'checklist/correoRechazar';
	public URLEnviarCorreoAutorizar =  'checklist/correoAutorizar';
	public URLObtenerListadoCK =  'checklist/obtenerListado';
	public URLObtenerListadoPorNumeroEmpleadoCK =  'checklist/ObtenerListadoPorNumeroEmpleado';
	public URLObtenerDetalleListadoCK =  'checklist/obtenerDetalleListado';
	public URLObtenerCheckListAsignados = 'checklist/ObtenerCheckListAsignados';
	public URLObtenerCheckListPorID = 'checklist/ObtenerCheckListPorID';
	public URLguardaCapturaCheckList = 'checklist/guardaCapturaCheckList';
	public URLObtenerSemanasSeleccionablesCHK = 'checklist/ObtenerSemanasSeleccionablesCHK';
	public URLObtenerCatalogoDeEstatus = 'checklist/obtenerCatalogoSeguimientoCHK';
	public URLObtenerRelacionDeCheckLists = 'checklist/ObtenerRelacionDeCheckLists';
	public URLguardarAccionesCorrectivas = 'checklist/guardarAccionesCorrectivas';
	public URLactualizaAccionesCorrectivasChecklist = 'checklist/actualizaAccionesCorrectivasChecklist';
	public URLconsultarAccionesCorrectivasPorIdCheckList = 'checklist/consultarAccionesCorrectivasPorIdCheckList';
	public URLvisualizarCheckList = 'checklist/visualizarCheckList';
	//EVIDENCIA
	public URLUploadEvidencia='EvidenciaCheckList/subir';
	public URLGetEvidenciaPorIdCheckList='EvidenciaCheckList/obtener';
	public URLGetAllEvidencias='EvidenciaCheckList/ObtenerTodos';
	public URLGetEvidenciasCorrectiva='EvidenciaCheckList/EvidenciaCorrectiva';
	public URLUploadEvidenciAC='EvidenciaCheckListAC/subir';
}

/////////////////////////// Reporte rendición de cuentas //////////////////////////////
export class ReporteRDCConstante{
	public URLObtenerReporteRDC = 'reporteRDC/obtenerReporteRDC';
	public URLobtenerReporteEmpresaRDC = 'reporteRDC/obtenerReporteEmpresaRDC';
	public URLObtenerReporteEspecificoRDC = 'reporteRDC/obtenerReporteEspecificoRDC';
	public URLobtenerSemanasRDC = 'reporteRDC/obtenerSemanasRDC';
}

//TALLEREs
export class TalleresConstante{
	public URLObtenerTalleres = 'talleres/consultaTalleres';
	public URLObtenerTaller = 'talleres/consultaTaller?TallerID=';
	public URLObtenerParticipantesTaller = 'talleres/obtenerParticipantesTaller?TallerID=';
	public URLObtenerSesionesTaller = 'talleres/obtenerSesionesTaller?TallerID=';
	public URLObtenerEntregablesTaller = 'talleres/obtenerEntregablesTaller?TallerID=';
	public URLObtenerDirectoresSubdirectores = 'talleres/obtenerDirectoresSubdirectores';
	public URLObtenerRelacionLideresSoporte = 'talleres/obtenerRelacionLideresSoporte';
	public URLObtenerRelacionLideresOperacion = 'talleres/obtenerRelacionLideresOperacion';
	public URLObtenerRelacionLideresSoporteByTaller = 'talleres/obtenerRelacionLiderSoporteByTaller?TallerID=';
	public URLObtenerRelacionLideresOperacionByTaller = 'talleres/obtenerRelacionLiderOperacionByTaller?TallerID=';
	public URLObtenerRelacionDirectoresGeneralesByTaller = 'talleres/obtenerRelacionDirectorGeneralByTaller?TallerID=';
	public URLObtenerCatalogoHerramientas = 'talleres/obtenerCatalogoHerramientas';
	public URLObtenerDirecciones = 'talleres/obtenerDirecciones';
	public URLObtenerAreas = 'talleres/obtenerAreas?IdDireccion=';
	public URLObtenerGteArquitecturaByArea = 'talleres/obtenerGteArquitecturaByArea?idArea=';
	public URLAreasHerramientasGuardar = 'talleres/guardarAreaHerramienta';
	public URLCrearTaller = 'talleres/creartaller';
	public URLCancelarTaller = 'talleres/cancelartaller';
	public URLAprobarAreasTrabajo = 'talleres/aprobarAreasTrabajo';
	public URLActualizarTaller = 'talleres/actualizarTaller';
	public URLLAGeneraPDF = 'talleres/generarPdf';
	public URLManejoDeFlujoAutorizacionTalleres = 'talleres/ManejoDeFlujoAutorizacionTalleres';
	public URLconsultaSolicitudesTalleres = 'talleres/consultaSolicitudesTalleres';
}

export class MejoraPropuestaConstantes {
	public URLDatosEmpleadoMejora = 'mejoraPropuesta/datosEmpleado?NumEmpleado=';
	public URLGuardaMejora = 'mejoraPropuesta/guardar';
	public URLDatosListadoMejoras = 'mejoraPropuesta/listadoMejoras';
	public URLDatosMejoras = 'mejoraPropuesta/datosMejora?idMejora=';
	public URLUpdateEstatusMejoras = 'mejoraPropuesta/updateEstatusMejora';
	public URLDatosListadoTemporalMejoras = 'mejoraPropuesta/listadoTemporalMejoras?NumEmpleado=';
	public URLEliminaMejoras = 'mejoraPropuesta/eliminaMejora?idMejora=';
	public URLDescargaDocumentoMejoras = 'mejoraPropuesta/descargaDocumentoMejora';
	public URLGestionMejora = 'mejoraPropuesta/gestionMejora';
	public URLConcluirMejora = 'mejoraPropuesta/concluirMejora';
	public URLEnviarCorreoConcluirMejora = 'mejoraPropuesta/enviarCorreoConcluir';
	public URLObtenerCatalogoMejoras = 'catalogoPropuestaMejoras/obtenerAll';
	public URLGuardarCatalogoMejoras = 'catalogoPropuestaMejoras/guardar';
	public URLUpdateTemporalMejora = 'mejoraPropuesta/updateTemporalMejora';
	public URLEnviarCorreoCentralizador = 'mejoraPropuesta/enviarCorreoCentralizador';
	public URLenviaCorreoCentralizadorChange = 'mejoraPropuesta/enviaCorreoCentralizadorChange';
	public URLEnviarCorreoResponsable = 'mejoraPropuesta/enviarCorreoResponsable';
	public URLUpdateFechaCompromisoMejora = 'mejoraPropuesta/updateFechaCompromisoMejora';
	public URLUpdateResponsableMejora = 'mejoraPropuesta/updateResponsable';
	public URLCreateCentralizadorTempMejora = 'mejoraPropuesta/createCentralizadorTemporal';
	public URLObtenerCentralizadoresTempMejora = 'mejoraPropuesta/obtenerCentralizadoresTemporal';
	public URLCerrarCentralizadorTempMejora = 'mejoraPropuesta/cerrarCentralizadorTemporal';
	public URLGetPosicionCentralizador = 'mejoraPropuesta/getPosicionCentralizador';
	public URLGetAllCentralizadores = 'mejoraPropuesta/getCentralizadorAll';
	public URLUpdateSolicitanteMejora = 'mejoraPropuesta/updateSolicitante';
	public URLUpdateEstatus = 'mejoraPropuesta/updateEstatus';
	public URLUpdateComentario = 'mejoraPropuesta/updateComentario';
	public URLChangeCentralizador = 'mejoraPropuesta/changeCentralizador';
	public URLSubirEvidenciaConcluir='mejoraPropuesta/uploadEvidencia';
	public URLTraerEvidencia='mejoraPropuesta/getEvidenciaConcluir';

	public URLTraerTodosCentralizadores='mejoraPropuesta/ObtenerAllCentralizadores';

}

export class ContactoConstantes {
  public URLSendEmail: string = "contacto/sendMail";
  public URLGuardarContacto: string = "contacto/GuardarContacto";
  public URLobtenerPreguntasContacto: string = "contacto/obtenerPreguntasContacto";
  public URLactualizarRespuesta: string = "contacto/actualizarRespuesta";
  public URLrespoEmail: string = "contacto/respoEmail";
}

export class ComunicacionConstantes {
	public URLObtenerComunicados = 'comunicacion/obtenerComunicados';
	public URLRegistrarComunicado = 'comunicacion/registrarComunicado';
	public URLUpdateEstatusComunicado = 'comunicacion/updateEstatusComunicado';
	public URLObtenerBanners = 'comunicacion/obtenerBanners';
	public URLRegistrarBanner = 'comunicacion/registrarBanner';
	public URLUpdateEstatusBanner = 'comunicacion/updateEstatusBanner';
	public URLActualizarOrdenBanner = 'comunicacion/actualizarOrdenBanner';
}

export class HKConstantes {
	public URLCIObtenerPuestosToken = 'Indicadores/CapturaReal/ObtenerPuestosToken';
	public URLCIObtenerHKDefaultToken = 'Indicadores/CapturaReal/hkpordefaulToken';
	public URLCIObtenerSemanasToken = 'Indicadores/CapturaReal/ObtenerSemanasToken';
	public URLCIObtenerReporteToken = 'Indicadores/CapturaReal/CargarReporteToken';
	public URLCIObtenerReporteCuatriToken = 'Indicadores/CapturaReal/ReporteEvaluacionCuatrimestralToken';
	public URLCIObtenerCumplimientoToken = 'Indicadores/Gfc_indicador_semanal/Cumplimiento_SimpleToken';
	public URLCIObtenerConceptosToken = 'Indicadores/CapturaReal/CargarCapturaRealToken';
	public URLCIguardaCapturaToken = 'Indicadores/CapturaReal/GuardarToken';
	public URLCIguardaConceptoPadreDistinctToken = 'Indicadores/CapturaReal/GuardaConceptoPadreToken';
	public URLCIguardaComentariosToken	= 'Indicadores/CapturaReal/GuardarComentariosToken';
	public URLCIguardaCapturaPptoToken = 'Indicadores/CapturaPpto/Guardar';	
	public URLCIguardarUltimoHkToken = 'Indicadores/CapturaReal/GuardaultimohkToken';
	public URLObtenerPermisoHk = 'ordos/ObtenerPermisoHk';
	public URLReporteEvaluacionDToken = 'Indicadores/CapturaReal/EvaluacionDesempenoToken';
	public URLGuardaCapturaEvaluacionDToken = 'Indicadores/CapturaReal/EvaluacionDesempenoToken';

	public URLguardarLogDescargaNiveldeUso = 'api/NivelesUso/MiGestionSK';
	public URLObtenerPermisoHerramientasAdmin = 'tuberias/ObtenerPermisoHerramientasAdmin';

	//CONSTANTES VERSION 2 HOSHIN KANRI

	public URLCIObtenerPuestosTokenV2 = 'V2/Indicadores/CapturaReal/ObtenerPuestosToken';
	public URLCIObtenerPuestoFechaToken = 'V2/Indicadores/CapturaReal/ObtenerPuestoFechaToken';
	public URLCIObtenerHKDefaultTokenV2 = 'V2/Indicadores/CapturaReal/hkpordefaulToken';
	public URLCIObtenerSemanasTokenV2 = 'V2/Indicadores/CapturaReal/ObtenerSemanasToken';
	public URLCIObtenerReporteTokenV2 = 'V2/Indicadores/CapturaReal/CargarReporteToken';
	public URLCIObtenerReporteCuatriTokenV2 = 'V2/Indicadores/CapturaReal/ReporteEvaluacionCuatrimestralToken';
	public URLCIObtenerCumplimientoTokenV2 = 'V2/Indicadores/Gfc_indicador_semanal/Cumplimiento_SimpleToken';
	public URLCIObtenerConceptosTokenV2 = 'V2/Indicadores/CapturaReal/CargarCapturaRealToken';
	public URLReporteEvaluacionDTokenV2 = 'V2/Indicadores/CapturaReal/EvaluacionDesempenoToken';
}

/////////////////////////// Herramienta RPD //////////////////////////////
export class RpdConstantes{
	public URLObtenerFrecuenciaReporteRpd = 'rpd/ObtenerFrecuenciaReporteRpd';
	public URLObtenerDocumentRpd = 'rpd/ObtenerDocumentRpd';
	public URLObtenerPermisoRpd = 'rpd/ObtenerPermisoRpd';
	// public URLObtenerFechaRpd = 'rpd/ObtenerFechaRpd';	
	public URLObtenerFechaReporte = 'tuberias/ObtenerFechaReporte';	
	public URLObtenerPermisoHerramientasAdmin = 'tuberias/ObtenerPermisoHerramientasAdmin';
	public URLObtenerHistorialDescarga = 'rpd/obtenerHistorialDescarga';
}

/////////////////////////// Herramienta Tuberías //////////////////////////////
export class TuberiasConstantes {
	public URLObtenerFrecuenciaReporteTuberia = 'tuberias/ObtenerFrecuenciaReporteTuberia';
	public URLObtenerDocumentTuberias = 'tuberias/ObtenerDocumentTuberias';
	public URLObtenerPermisoTuberias = 'tuberias/ObtenerPermisoTuberias';
	public URLObtenerFechaReporte = 'tuberias/ObtenerFechaReporte';	
	public URLObtenerPermisoHerramientasAdmin = 'tuberias/ObtenerPermisoHerramientasAdmin';
}

/////////////////////////// Herramienta AArq Juntas //////////////////////////////
export class JuntasConstantes{
	public URLObtenerJuntaNombreArq = 'AArqjuntas/ObtenerJuntaNombreArq';
	public URLObtenerJuntaNumeroArq = 'AArqjuntas/ObtenerJuntaNumeroArq';
	public URLObtenerJuntaMiGestionNombreArq = 'AArqjuntas/ObtenerJuntaMiGestionNombreArq';
	public URLObtenerJuntaMiGestionNumeroArq = 'AArqjuntas/ObtenerJuntaMiGestionNumeroArq';
	public URLObtenerNombreRolesArq = 'AArqjuntas/ObtenerNombreRolesArq';
	public URLObtenerPremisesArq = 'AArqjuntas/ObtenerPremisesArq';
	public URLObtenerAgendaTipoArq = 'AArqjuntas/ObtenerAgendaTipoArq';
	public URLGuardarJuntaenAArq = 'AArqjuntas/GuardarJuntaenAArq';
	public URLvalidarExisteJunta = 'AArqjuntas/validarExisteJunta';	

	//JUNTAS MI GESTION AREA ARQ.
	public URLObtenerJuntaNombreMG = 'AArqjuntas/ObtenerJuntaNombreMG';
	public URLObtenerJuntaNumeroMG = 'AArqjuntas/ObtenerJuntaNumeroMG';
	public URLObtenerNombreRolesMG = 'AArqjuntas/ObtenerNombreRolesMG';
	public URLObtenerNombreRolSargentoMG = 'AArqjuntas/ObtenerNombreRolSargentoMG';
	public URLObtenertimeMG = 'AArqjuntas/ObtenertimeMG';
	public URLObtenerPremisesMG = 'AArqjuntas/ObtenerPremisesMG';
	public URLObtenerAgendaTipoMG = 'AArqjuntas/ObtenerAgendaTipoMG';
	public URLAgregarParticipanteMG = 'AArqjuntas/AgregarParticipanteMG';
	public URLAgregarPremiseMG = 'AArqjuntas/AgregarPremiseMG';
	public URLAgregarAgendaTipoMG = 'AArqjuntas/AgregarAgendaTipoMG';
	public URLGuardarJuntaenMG = 'AArqjuntas/GuardarJuntaenMG';
	public URLEliminarJuntaMG = 'AArqjuntas/EliminarJuntaMG';
	public URLEliminarAgendaTipoMG = 'AArqjuntas/EliminarAgendaTipoMG';
	public URLEliminarParticipanteMG = 'AArqjuntas/EliminarParticipanteMG';
	

    //CREAR JUNTAS MI GESTION AREA ARQ.
	public URLConsultaUltimaJunta = 'AArqjuntas/ConsultaUltimaJunta';
	public URLGuardarJuntaCreada = 'AArqjuntas/GuardarJuntaCreada';

	//REPORTE JUNTAS
	public URLObtenerReporteJuntaNombreMG = 'AArqjuntas/ObtenerReporteJuntaNombreMG';
	public URLObtenerReporteJuntaNombreSJ = 'AArqjuntas/ObtenerReporteJuntaNombreSJ';
	// public URLObtenerReporteJuntaNumeroMG = 'AArqjuntas/ObtenerReporteJuntaNumeroMG';
	
}

////////////INDICE DESEMPEÑO //////////////////////////////
export class IndiceDesempenoConstantes {
	public URLCreateComponent 				= 'indiceDesempeno/createComponent';
	public URLGetListComponent 				= 'indiceDesempeno/getListComponent';
	public URLDeleteComponent 				= 'indiceDesempeno/deleteComponent';
	public URLCreatePeriod 					= 'indiceDesempeno/createPeriod';
	public URLGetListCatPeriod 				= 'indiceDesempeno/getListCatPeriod';
	public URLGetListPeriod 				= 'indiceDesempeno/getListPeriod';
	public URLGetListAnioConfig				= 'indiceDesempeno/getListAnioConfig';
	public URLGetListPeriodConfig			= 'indiceDesempeno/getListPeriodConfig';
	public URLGetPeriodFilter				= 'indiceDesempeno/getPeriodWithFilter';
	public URLGetCapturePercent				= 'indiceDesempeno/getCapturePercent';
	public URLGetFuncionesReplica			= 'indiceDesempeno/getFuncionesReplica';
	public URLGetColaboradoresReplica		= 'indiceDesempeno/getColaboradoresFuncionesReplica';
	public URLCreateIndColaboradores		= 'indiceDesempeno/createIndColaboradores';
	public URLGetIndColaboradores			= 'indiceDesempeno/getIndColaboradores';
	public URLDeleteIndColaborador			= 'indiceDesempeno/deleteIndColaborador';
	public URLGetComponentColaboradorNA		= 'indiceDesempeno/getComponentColaboradorNA';
	public URLGetCapturaFOC					= 'indiceDesempeno/getCapturaFOC';
	public URLGetCapturaEvaluacion		    = 'indiceDesempeno/getCapturaEvaluacion';
	public URLDeletPeriod		    		= 'indiceDesempeno/deletePeriod';
	public URLGetPermissionColaborador		= 'indiceDesempeno/getPermissionColaborador';
	public URLGetCapturaAuditoria			= 'indiceDesempeno/getCapturaAuditoria';
	public URLCargaDatosExcel				= 'indiceDesempeno/cargaDatosExcel';
	public URLRankingIndice					= 'indiceDesempeno/resultadoAutomaticoIndice';
	public URLGetHistorialResult			= 'indiceDesempeno/getListHistorialResult';
	public URLDeleteResultRanking			= 'indiceDesempeno/deleteResultRanking';
	public URLGetResultColaborador			= 'indiceDesempeno/getResultPeriodoColaborador';
	public URLGetColaboradoresResult		= 'indiceDesempeno/getColaboradoresResultado';
	public URLGetCalculoIndividual			= 'indiceDesempeno/calculoIndividualIndice';
	public URLGetObtenerPlantilla			= 'indiceDesempeno/obtenerPlantilla';
	public URLCreatePeriodAnual				= 'indiceDesempeno/createPeriodAnual';
	public URLGetPeriodFilterAnual			= 'indiceDesempeno/getPeriodWithFilterAnual';
	public URLGetResultColaboradorAnual		= 'indiceDesempeno/getResultPeriodoColaboradorAnual';
	public URLGetColaboradoresCalibracion	= 'indiceDesempeno/obtenerColaboradoresCalibracion';
	public URLGetListPremisas				= 'indiceDesempeno/getListPremisas';
	public URLCreateCalibracion				= 'indiceDesempeno/creatCalibracion';
	public URLUpdateEstatusCalibracion		= 'indiceDesempeno/updateEstatusCalibracion';
}

////////////Auditoria de Cultura //////////////////////////////
export class AuditoriaCulturaConstantes {
	public URLCreateBlock					= 'auditoriCultura/creatBlock';
	public URLGetListBlocks 				= 'auditoriCultura/getListBlocks';
	public URLDeleteBlock					= 'auditoriCultura/deleteBlock';
	public URLCreatePeriod					= 'auditoriCultura/creatPeriod';
	public URLGetListPeriod 				= 'auditoriCultura/getListPeriod';
	public URLGetListContempla 				= 'auditoriCultura/getListContempla';
	public URLGetListSelect					= 'auditoriCultura/getListSelect';
	public URLGetListAnioConfig				= 'auditoriCultura/getListAnioConfig';
	public URLGetListPeriodConfig			= 'auditoriCultura/getListPeriodConfig';
	public URLGetPeriodFilter				= 'auditoriCultura/getPeriodWithFilter';
	public URLGetResultContempla			= 'auditoriCultura/getResultContempla';
	public URLGetNumSemana					= 'auditoriCultura/getNumSemana';
	public URLGetObtenerDocument			= 'auditoriCultura/obtenerDocumento';
	public URLGetPermissionColaborador		= 'auditoriCultura/getPermissionColaborador';
	public URLGetTiposUnidad				= 'auditoriCultura/getTiposUnidad';
	public URLGetReporteAccionesCorretivas	= 'auditoriCultura/getReporteAccionesCorretivas';
}

/////////////////////// Informes //////////////////////////////

export class InformesConstantes{
	public URLObtenerFrecuenciaReporteInforme = 'informes/ObtenerFrecuenciaReporteInformes';
	public URLObtenerNomInformes = 'informes/ObtenerNomInformes';
	public URLObtenerDocumentInforme = 'informes/ObtenerDocumentInforme';
	public URLObtenerFechaInforme = 'informes/ObtenerFechaInforme';
	public URLValidarNomInforme = 'informes/ValidarNomInforme';
	public URLCargaInformes = 'informes/CargaInformes';
	public URLPermisoCargaInformes = 'informes/permisoCargaInformes';
	public URLPermisoInformes = 'informes/permisoVerInformes';
	public URLObtenerRutaDocumento = 'informes/obtenerRutaDocumento';
	public URLObtenerFechaInformes = 'informes/ObtenerFechaInformes';	
	public URLObtenerPermisoHerramientasAdmin = 'tuberias/ObtenerPermisoHerramientasAdmin';
}