import { Component, OnInit,Input, AfterViewInit, ViewChild, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ListaNotificacionesComponent } from './lista-notificaciones/lista-notificaciones.component';
import { CookieService } from 'ngx-cookie-service';
import {MatIconModule} from '@angular/material/icon';
import Swal from 'sweetalert2';

import {
  NotificacionesDashboardService,
  EmpleadoService,
}from '../../services';
import { animate } from '@angular/animations';
import { async } from '@angular/core/testing';
import { log } from 'console';
export interface HeaderDashboard {
  dataHeader:any;
}
@Component({
  selector: 'app-modal-notificaciones',
  templateUrl: './modal-notificaciones.component.html',
  styleUrls: ['./modal-notificaciones.component.scss']
})
export class ModalNotificacionesComponent implements OnInit{
  session: any;

 /*  
  PorcentajeOrdos:number = 100;
  PorcentajeDecisiones:number = 100;
  PorcentajeSesiones:number = 100;
  PorcentajeLA:number = 100; */


  NotificacionesOrdos:number;
  NotificacionesDecisiones:number;
  NotificacionesSesiones:number;
  NotificacionesLA:number;
  NotificacionesJuntas:number;
  NotificacionesJuntasCompromisos:number;
  NotificacionesDecisionesLeer:number;
  NotificacionesDecisionesTotal:number;
  NotificacionesModoSK:number;
  NotificacionesJuntasCompro:number = 0;
  NotificacionesModoSK_Solicitante: any;
  NotificacionesModoSKTotal: number;

  TotalNotificacionesHago:number = 0;
  TotalAtendidoHago:number =0;
  TotalNotificacionesDecido:number = 0;
  TotalAtendidoDecido:number =0;

  TituloLista:String;
  Lista:any=[];
  ListaCompromisos:any=[];
  ListaDLeer:any=[];
  ListaModosk_Solicitante:any=[];
  iconoLista:String;

  btnOrdos:boolean = true;
  btnLa:boolean = true;
  btnSesiones:boolean = true;
  btnFoc:boolean = true;
  btnJuntas:boolean = true;
  btnModoSk:boolean = true;
  btnCC:boolean = true;
  btnDecisiones:boolean = true;
  btnRPD:boolean = true;
  btnTuberias:boolean = true;
  btnHK:boolean = true;
  ADecisionesLeer = 0;
  NDecisionesLeer = 0;


  Posicion:number;
  Grupo:number;
  NJuntas:number = 0;
  AJuntas:number = 0;
  NJuntasAntes:number = 0;
  AJuntasAntes:number = 0;

  ASolAtendidas_Responsable:number = 0;
  NSol_Responsable: number = 0;
  ASolAtendidas_Solicitante:number = 0;
  NSol_Solicitante:number = 0;
  NotificacionesDataCC_rh: any;
  NotificacionesDataCC_usuario: any;
  ListaCC_usuario: any;
  Notificaciones_CC_Total: any;
  NotificacionesDataCC_jefe: any;
  ListaCC_jefe: any;
  NotificacionesData_PdM: number;
  Lista_PdM_Solicitante;
  Lista_PdM_Centralizador;
  Lista_PdM_DuenoProceso;
  Lista_PdM_Admin;
  ASolPendientes_Responsable:number = 0;
  ASolPendientes_Solicitante:number = 0;
  ADecisionesPendientes:number = 0;

  constructor( 
    private _ND:NotificacionesDashboardService,
    public listDialog: MatDialog,
    public dialogRef: MatDialogRef<ListaNotificacionesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataHeader: HeaderDashboard,
    private _cockieService: CookieService )
    { 
      this.session = (this._cockieService.get('session')) ? JSON.parse(this._cockieService.get('session')) : null;
    }
    CloseDashboard(){
      this.dialogRef.close();
    }

  ngOnInit() {
    
    this.Posicion = this.dataHeader['posicion'];
    this.Grupo = this.dataHeader['grupo'];
    
    this.contadores();
    this.CtrlBotones();

    this.totalNotificacionesJYC(this.session.user.NumeroEmpleado);
    this.totalNotificacionesAtendidasJYC(this.session.user.NumeroEmpleado);
    this.totalNotificacionesDecisiones(this.session.user.NumeroEmpleado);
    this.notificacionesModoSKTotalizados(this.session.user.NumeroEmpleado);
  }

  contadores(){
    if (this.dataHeader['dataOrdo'].length === undefined) {
      this.NotificacionesOrdos=0;
    }else{
      this.NotificacionesOrdos = this.dataHeader['dataOrdo'].length;
    }

    if (this.dataHeader['dataDecision'].length === undefined) {
      this.NotificacionesDecisiones=0;
    }else{
      this.NotificacionesDecisiones = this.dataHeader['dataDecision'].length;
      console.log(this.NotificacionesDecisiones)
    }

    if (this.dataHeader['dataDecisionLeer'].length === undefined) {
      this.NotificacionesDecisionesLeer=0;
    }else{
      console.log(this.dataHeader['dataDecisionLeer']);
      this.NotificacionesDecisionesLeer = this.dataHeader['dataDecisionLeer'].length;
    }

    this.NotificacionesDecisionesTotal = (this.NotificacionesDecisiones + this.NotificacionesDecisionesLeer);

    if (this.dataHeader['dataSesiones'].length === undefined) {
      this.NotificacionesSesiones=0;

    }else{
      this.NotificacionesSesiones = this.dataHeader['dataSesiones'].length;
    }

    if (this.dataHeader['dataLA'].length === undefined) {
      this.NotificacionesLA=0;
    }else{
      console.log(this.dataHeader['dataLA']);
      this.NotificacionesLA = this.dataHeader['dataLA'].length;
    }

    if (this.dataHeader['dataLA'].length === undefined) {
      this.NotificacionesLA=0;
    }else{
      console.log(this.dataHeader['dataLA']);
      this.NotificacionesLA = this.dataHeader['dataLA'].length;
    }

    if (this.dataHeader['dataJuntas'].length === undefined) {
      this.NotificacionesJuntas=0;
    }else{
      console.log(this.dataHeader['dataJuntas']);
      this.NotificacionesJuntas = this.dataHeader['dataJuntas'].length;
    }

    if (this.dataHeader['DataJuntasCompromisos'].length === undefined) {
      this.NotificacionesJuntasCompromisos=0;
    }else{
      console.log(this.dataHeader['DataJuntasCompromisos']);
      this.NotificacionesJuntasCompromisos = this.dataHeader['DataJuntasCompromisos'].length;
    }

    this.NotificacionesJuntasCompro = 0;
    this.NotificacionesJuntasCompro = this.NotificacionesJuntas + this.NotificacionesJuntasCompromisos
    // console.log(this.NotificacionesJuntasCompro)

    if (this.dataHeader['dataModoSk'].length === undefined) {
      this.NotificacionesModoSK=0;
    }else{
      this.NotificacionesModoSK = this.dataHeader['dataModoSk'].length;
    }

    if (this.dataHeader['dataModoSK_Solicitante'].length === undefined) {
      this.NotificacionesModoSK_Solicitante=0;
    }else{
      this.NotificacionesModoSK_Solicitante = this.dataHeader['dataModoSK_Solicitante'].length;
    }

    this.NotificacionesModoSKTotal = 0;
    this.NotificacionesModoSKTotal = this.NotificacionesModoSK + this.NotificacionesModoSK_Solicitante;

    
    if (this.dataHeader['dataCC_usuario'].length === undefined) {
      this.NotificacionesDataCC_usuario=0;
    }else{
      this.NotificacionesDataCC_usuario = this.dataHeader['dataCC_usuario'].length;
    }

    if (this.dataHeader['dataCC_jefe'].length === undefined) {
      this.NotificacionesDataCC_jefe=0;
    }else{
      this.NotificacionesDataCC_jefe = this.dataHeader['dataCC_jefe'].length;
    }

    if (this.dataHeader['dataCC_rh'].length === undefined) {
      this.NotificacionesDataCC_rh=0;
    }else{
      this.NotificacionesDataCC_rh = this.dataHeader['dataCC_rh'].length;
    }
  
    if (this.dataHeader['data_PdM'] === undefined) {
      this.NotificacionesData_PdM=0;

    }else{
      this.NotificacionesData_PdM = this.dataHeader['data_PdM'];
    }


    this.Notificaciones_CC_Total = this.NotificacionesDataCC_usuario + this.NotificacionesDataCC_jefe + this.NotificacionesDataCC_rh;
  }
  openList(h){
    this.listaHerramienta(h);
      this.listDialog.open(ListaNotificacionesComponent,{
        panelClass: 'custom-dialog-container',
        width:'500px',
        maxHeight:'60vh',
       data:{
          Titulo: this.TituloLista,
          Icono: this.iconoLista,
          Items: this.Lista,
          ItemsJC: this.ListaCompromisos,
          ItemsDLeer: this.ListaDLeer,
          ItemsModoSK_Solicitante: this.ListaModosk_Solicitante,
          ItemsCC_usuario: this.ListaCC_usuario,
          ItemsCC_jefe: this.ListaCC_jefe,
          Items_PdM_Solicitante: this.Lista_PdM_Solicitante,
          Items_PdM_Centralizador: this.Lista_PdM_Centralizador,
          Items_PdM_DuenoProceso: this.Lista_PdM_DuenoProceso,
          Items_PdM_Admin: this.Lista_PdM_Admin,
          Rol:this.dataHeader['rol']
        }
      });
  }
  listaHerramienta(h){
    this.TituloLista = h;
    switch(this.TituloLista){
      case "ORDO":
        this.Lista = this.dataHeader['dataOrdo'];
        this.iconoLista =  'fa fa-bullseye';
      break;
      case "DECISIONES":  
        this.Lista = this.dataHeader['dataDecision'];
        this.ListaDLeer = this.dataHeader['dataDecisionLeer'];
        this.iconoLista =  'fa fa-book';
      break;
      case "SESIONES 1:1":
        this.Lista = this.dataHeader['dataSesiones'];
        this.iconoLista =  'fa fa-handshake-o';
      break;
      case "LA":
        this.Lista = this.dataHeader['dataLA'];
        this.iconoLista = 'fa fa-check-square-o'
      break;
      case "JUNTAS":
        this.Lista = this.dataHeader['dataJuntas'];
        this.ListaCompromisos = this.dataHeader['DataJuntasCompromisos'];
        this.iconoLista = 'fa fa-check-square-o'
      break;
      case "MODO SK":
        this.Lista = this.dataHeader['dataModoSk'];
        this.ListaModosk_Solicitante = this.dataHeader['dataModoSK_Solicitante'];
        this.iconoLista = 'fa fa-check-square-o'
      break;
      case "CULTURA DE CUMPLIMIENTO":
        this.Lista = this.dataHeader['dataCC_rh'];
        this.ListaCC_usuario = this.dataHeader['dataCC_usuario'];
        this.ListaCC_jefe = this.dataHeader['dataCC_jefe'];
        this.iconoLista = 'fa fa-check-square-o'
      break;
      case "PROPUESTA DE MEJORA":
        this.Lista_PdM_Solicitante = this.dataHeader['data_PdM_Solicitante'];
        this.Lista_PdM_Centralizador = this.dataHeader['data_PdM_Centralizador'];
        this.Lista_PdM_DuenoProceso = this.dataHeader['data_PdM_DuenoProceso'];
        this.Lista_PdM_Admin = this.dataHeader['data_PdM_Admin'];
        this.iconoLista =  'fa fa-bullseye';
      break;
      default:
        break;
    }
  }
/*  Observador(){
  
  this._ND.obtenerHerramientas().subscribe(herramientas =>{
    
    
});
 } */

 totalNotificacionesJYC(nEmpleado){

  this.NJuntas = 0;
  this._ND.obtenerTotalJYC(nEmpleado).subscribe((res:any)=>{
    console.log('Total Juntas',res)
    this.NJuntas = res.NotificacionesDashboard[0].totalJC;
    this.NJuntasAntes = res.NotificacionesDashboard[0].totalJCAnt;
  });

 }

 totalNotificacionesDecisiones(nEmpleado){

  // this.NJuntas = 0;
  this._ND.decisionesTotalLeer(nEmpleado,this.Posicion).subscribe((res:any)=>{
    console.log(res)
    this.ADecisionesLeer = res.NotificacionesDashboard[0].totalDecisionesLeidas;
    this.NDecisionesLeer = res.NotificacionesDashboard[0].totalDecisionesLeer;
    this.ADecisionesPendientes =  res.NotificacionesDashboard[0].totalDecisionesPendientes;
  });

 }

 async totalNotificacionesAtendidasJYC(nEmpleado){

  this.AJuntas = 0;
  await this._ND.obtenerAtendidasJYC(nEmpleado).toPromise().then((res:any)=>{
    console.log('Total Juntas Atendidas',res)
    this.AJuntas = res.NotificacionesDashboard[0].totalJC;
    this.AJuntasAntes = res.NotificacionesDashboard[0].totalJCAnt;
  });

  await this.historialNotificaciones(this.Posicion,this.Grupo);

 }

 notificacionesModoSKTotalizados(nEmpleado){

  this.ASolAtendidas_Responsable = 0;
  this.NSol_Responsable = 0;
  this.ASolAtendidas_Solicitante = 0;
  this.NSol_Solicitante = 0;
  this.ASolPendientes_Responsable = 0;
  this.ASolPendientes_Solicitante = 0;

  this._ND.notificacionesModoSKTotalizados(nEmpleado,this.Posicion).subscribe((res:any)=>{
    console.log('MODO SK',res)
    this.ASolAtendidas_Responsable = res.NotificacionesDashboard[0].TotalSolAtendidas_Responsable;
    this.NSol_Responsable = res.NotificacionesDashboard[0].TotalSol_Responsable;
    this.ASolAtendidas_Solicitante = res.NotificacionesDashboard[0].TotalSolAtendidas_Solicitante;
    this.NSol_Solicitante = res.NotificacionesDashboard[0].TotalSol_Solicitante;
    this.ASolPendientes_Responsable = res.NotificacionesDashboard[0].TotalPen_Responsable;
    this.ASolPendientes_Solicitante = res.NotificacionesDashboard[0].TotalPen_Solicitante;
  });

 }

 historialNotificaciones(Posicion, Grupo){
  // console.log(Posicion)
  // console.log(Grupo)

  
     var fecha = new Date();
     var mes = fecha.getMonth()+1;
     var año = fecha.getFullYear();
     this._ND.historialNotificaciones(Posicion,Grupo,mes,año).subscribe((res:any)=>{
      
      let historial = res.NDHistorial
      // console.log("Grupo ",Grupo);
      // console.log(historial)
      
      
      let NOrdo = 0;
      let NLa = 0;
      let NSesiones = 0;
      let NDecisiones = 0;

      let AOrdo = 0;
      let ALa = 0;
      let ASesiones = 0;
      let ADecisiones = 0;

      let NoAtendidasOrdo = 0;
      let NoAtendidasLa = 0;
      let NoAtendidasSesiones = 0;
      let NoAtendidasDecisiones = 0;

      let juntasAntes = 0;

      juntasAntes = this.NJuntasAntes - this.AJuntasAntes;
      
      for (let i = 0; i < historial.length; i++) {
        if (historial[i]["Herramienta"] == 'ORDOS') {
          
          if(año == historial[i]["Año"] ){
            AOrdo =AOrdo +historial[i]["NotificacionesAtendidas"];
            NOrdo =NOrdo +historial[i]["TotalNotificaciones"];
          }else{
            NOrdo =NOrdo + (historial[i]["TotalNotificaciones"] - historial[i]["NotificacionesAtendidas"]);
          }
        }
        if (historial[i]["Herramienta"] == 'LA') {
          
          if(año == historial[i]["Año"] ){
            ALa = ALa+historial[i]["NotificacionesAtendidas"];
            NLa = NLa+historial[i]["TotalNotificaciones"];
          }else{
            NLa = NLa + (historial[i]["TotalNotificaciones"] - historial[i]["NotificacionesAtendidas"]);
          }
        }
        if (historial[i]["Herramienta"] == 'SESIONES') {
          
          if(año == historial[i]["Año"] ){
            ASesiones = ASesiones +historial[i]["NotificacionesAtendidas"];
            NSesiones = NSesiones +historial[i]["TotalNotificaciones"];
          }else{
            NSesiones = NSesiones + (historial[i]["TotalNotificaciones"] - historial[i]["NotificacionesAtendidas"]);
          }
        }
        if (historial[i]["Herramienta"] == 'DECISIONES') {

          
          if(año == historial[i]["Año"] ){
            ADecisiones =ADecisiones+ historial[i]["NotificacionesAtendidas"];
            NDecisiones = NDecisiones+historial[i]["TotalNotificaciones"];
          }else{
            NDecisiones = NDecisiones + (historial[i]["TotalNotificaciones"] - historial[i]["NotificacionesAtendidas"]);
          }
        }
      }

      /* Se suman todas las notificaciones no atendidas de especificas del usuario y en generales del usuario */
      /* let NoAtendidasUsuarioH = NoAtendidasOrdo + NoAtendidasLa + NoAtendidasSesiones;
      let NoAtendidasGeneraH = this.NotificacionesOrdos + this.NotificacionesSesiones + this.NotificacionesLA

      let NoAtendidasUsuarioD = NoAtendidasDecisiones;
      let NoAtendidasGeneraD = this.NotificacionesDecisiones; */
     /* Se obtiene la diferencia si es que hay, de notificaciones de generales y especificas no atendidas para saber si hay notificaciones para un rol (Administrador o Arquitecto) */
      /* let diferencia1 = NoAtendidasGeneraH - NoAtendidasUsuarioH;
      
      let diferencia2 = NoAtendidasGeneraD - NoAtendidasUsuarioD */

    /* [Las siguientes sumatorias sirven para que en la función Aguja se puedan tener los porcentajes de atención] */  

     /* Se suman las Notificaciones que hay en HAGO y notificaciones de ROL --*/
    //  console.log('NOrdo',NOrdo)
    //  console.log('NLa',NLa)
    //  console.log('NSesiones', NSesiones);
    // //  console.log('NDECISIONES',NDecisiones)
    // //  console.log('ADECISIONES',ADecisiones)
    //  console.log('njuntas',this.NJuntas)
    //  console.log('nresponsable',this.NSol_Responsable)
    //  console.log('nsolicitante',this.NSol_Solicitante)

     this.TotalNotificacionesHago = NOrdo + NLa + NSesiones + this.NJuntas + Number(this.NSol_Responsable) + Number(this.NSol_Solicitante) + Number(juntasAntes) + Number(this.ASolPendientes_Responsable) + Number(this.ASolPendientes_Solicitante);//+ diferencia1;
      // console.log("T ",+this.TotalNotificacionesHago);
      
     this.TotalNotificacionesDecido = NDecisiones + Number(this.NDecisionesLeer) + Number(this.ADecisionesPendientes);//+ diferencia2;
     /* Se suman el total de notificaciones Atendidas que tiene el usuario en el historial*/

    //  console.log(AOrdo)
    //  console.log(ALa)
    //  console.log(ASesiones)
    //  console.log(this.AJuntas)
    //  console.log(this.ASolAtendidas_Responsable)
    //  console.log(this.ASolAtendidas_Solicitante)

     this.TotalAtendidoHago = AOrdo + ALa + ASesiones + this.AJuntas + Number(this.ASolAtendidas_Responsable) + Number(this.ASolAtendidas_Solicitante);
     console.log("A ", +this.TotalAtendidoHago)
     this.TotalAtendidoDecido = ADecisiones + Number(this.ADecisionesLeer);
    
    //  console.log('DECIDO TOTAL', this.TotalNotificacionesDecido);
    //  console.log('DECIDO ATENDIDO', this.TotalNotificacionesDecido);
     this.Aguja();
       
    });
}
Aguja(){
  console.log("velocimetros");

  let RendimientoHago = 100 * 1.8;
  
  this.TotalAtendidoHago = this.TotalAtendidoHago == 0 ? 1 : this.TotalAtendidoHago;
  this.TotalAtendidoDecido  = this.TotalAtendidoDecido  == 0 ? 1 : this.TotalAtendidoDecido ;
  
  let RendimientoDecido = 100 * 1.8;

  if (this.TotalNotificacionesHago != 0) {
   let calculo1 = (this.TotalAtendidoHago * 100) / this.TotalNotificacionesHago;
   
    if(calculo1 <= 0){
      calculo1 =0;  
    }
    if (calculo1 > 100) {
      calculo1 = 100;
    }

    RendimientoHago = calculo1 *1.8;
  }
  
  if (this.TotalNotificacionesDecido != 0) {
    let calculo2 = (this.TotalAtendidoDecido * 100) / this.TotalNotificacionesDecido;
    if (calculo2 <= 0) {
      calculo2 =0; 
    }
    if (calculo2 > 100) {
      calculo2 = 100;
    }
    RendimientoDecido = calculo2 * 1.8;
  }

  console.log('rendimientohago', RendimientoHago);
  console.log('rendimiento', RendimientoDecido);
  this.vibracion(RendimientoHago, "Ahago");
  this.vibracion(RendimientoDecido, "ADecido");

}

vibracion(Atencion,velocimetro){
  let av = document.getElementById(velocimetro);
  let v = document.getElementById(velocimetro);
  // v.style.cssText="transform:rotate("+Atencion+"deg);";
  //let arranque = (100 * 1.8) - Atencion;

  
   v.animate([
      {transform: "Rotate("+(Atencion)+"deg)"},
      {transform: "Rotate("+(Atencion + 1)+"deg)"},
      {transform: "Rotate("+(Atencion)+"deg)"},
      {transform: "Rotate("+(Atencion - 1)+"deg)"},
      {transform: "Rotate("+(Atencion)+"deg)"},
    ],{
        iterations:Infinity,
        duration:250
    })
}

CtrlBotones(){
  //let Herramientas:any = this._ND.getHerramientas()
 // Herramientas.forEach(herramienta => {
    //switch (herramienta) {
      //HAGO
    //  case 'ORDO':
        this.btnOrdos = !this.btnOrdos;
    //  break;
    //  case 'LA':
        this.btnLa = !this.btnLa;
    //  break;
     // case 'SESIÓN 1:1':
        this.btnSesiones =!this.btnSesiones;
     // break;
      /* case 'FOC':
        this.btnFoc = !this.btnFoc;
      break; */
      //case 'JUNTAS':
        this.btnJuntas = !this.btnJuntas;
      //break;
      /* case 'MODO SK':*/ 
        this.btnModoSk = !this.btnModoSk;
      //break;
      //DECIDO
     // case 'DECISIONES SK':
        this.btnDecisiones = !this.btnDecisiones;
      //break;
     /*  case 'RPD':
        this.btnRPD = !this.btnRPD;
      break; */
      /* case 'TUBERÍAS':
        this.btnTuberias = !this.btnTuberias;
      break; */
      /* case 'HOSHIN KANRI':
        this.btnHK = !this.btnHK;
      break; */
      //CULTURA DE CUMPLIMIENTO
      // case 'CULTURA CUMPLIMIENTO': 
       // this.btnCC = !this.btnCC;
      //break; 
    //}

  
  
  
  }
}